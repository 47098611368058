import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./BursariesEligibility.module.scss";

import BoxHeading from "../BoxHeading/BoxHeading";
import SanityImage from "../SanityImage/SanityImage";

import Lottie from "lottie-react";

import CheckMarkLottie from "../../assets/lottie/check-mark.json";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

import EligibilityCircle from "../../assets/images/eligibility-circle.webp";
import NoteIcon from "../../assets/images/note-icon.webp";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function BursariesEligibility({
    className,
    title,
    description,
    circleDescription,
    qualificationsList1,
    qualificationsList2,
    shapeImage,
    noteTitle,
    noteText,
}) {
    const blocks = [qualificationsList1, qualificationsList2];
    const columnRefs = useRef([]);
    const shapeRef = useRef();
    const { isMobile } = useBreakpoint();
    const blocksContainerRef = useRef();
    const observer = useRef();
    const hasIntersected = useRef(false);
    const [lottiesToShow, setLottiesToShow] = useState([]);

    useEffect(() => {
        if (!blocksContainerRef.current) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !hasIntersected.current) {
                        hasIntersected.current = true;

                        let i = 0;

                        const int = setInterval(() => {
                            setLottiesToShow((prev) => [...prev, i]);
                            i++;

                            if (i === 10) {
                                clearInterval(int);
                            }
                        }, 300);
                    }
                });
            },
            { threshold: 0, rootMargin: "0px 0px -25% 0px" }
        );

        observer.current.observe(blocksContainerRef.current);

        return () => {
            observer.current?.disconnect();
        };
    }, []);

    useEffect(() => {
        gsap.killTweensOf([...columnRefs.current, shapeRef.current]);
        gsap.set([...columnRefs.current, shapeRef.current], {
            clearProps: "all",
        });

        if (isMobile) return;

        gsap.to(columnRefs.current[0], {
            y: "-5vh",
            scrollTrigger: {
                trigger: columnRefs.current[0],
                start: "top bottom",
                end: "bottom top",
                scrub: 0.75,
            },
        });

        gsap.to(columnRefs.current[1], {
            y: "5vh",
            scrollTrigger: {
                trigger: columnRefs.current[1],
                start: "top bottom",
                end: "bottom top",
                scrub: 0.75,
            },
        });

        gsap.fromTo(
            shapeRef.current,
            {
                rotate: "40deg",
                y: "-20rem",
            },
            {
                rotate: 0,
                y: 0,
                scrollTrigger: {
                    trigger: shapeRef.current,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 1,
                },
            }
        );
    }, [isMobile]);

    if (
        !title ||
        !description ||
        !circleDescription ||
        !qualificationsList1 ||
        !qualificationsList2 ||
        !shapeImage
    ) {
        return null;
    }

    return (
        <div className={classnames(styles.BursariesEligibility, className)}>
            <div className={styles.inner}>
                <div
                    className={styles.blocksContainer}
                    ref={blocksContainerRef}
                >
                    {blocks.map((block, i) => (
                        <div
                            key={i}
                            className={styles.blocksColumn}
                            ref={(ref) => (columnRefs.current[i] = ref)}
                        >
                            {i === 0 ? (
                                <div className={styles.topContentLeft}>
                                    <h1
                                        className={styles.topContentLeft__title}
                                    >
                                        {title}
                                    </h1>
                                    <p
                                        className={
                                            styles.topContentLeft__description
                                        }
                                    >
                                        {description}
                                    </p>
                                </div>
                            ) : (
                                <div
                                    className={
                                        styles.circleDescriptionContainer
                                    }
                                >
                                    <img
                                        className={styles.circle}
                                        src={EligibilityCircle.src}
                                        alt=""
                                    />
                                    {circleDescription}
                                </div>
                            )}
                            <div className={styles.block}>
                                <BoxHeading
                                    className={styles.block__heading}
                                    text={block.title}
                                    theme={i === 0 ? "yellow" : "blue"}
                                />
                                <div className={styles.block__content}>
                                    <div className={styles.block__colHeadings}>
                                        <div
                                            className={styles.block__colHeading}
                                        >
                                            Y / N
                                        </div>
                                        <div
                                            className={styles.block__colHeading}
                                        >
                                            requirements
                                        </div>
                                    </div>
                                    <div className={styles.block__colContent}>
                                        {block.values.map((value, i) => (
                                            <div
                                                key={i}
                                                className={
                                                    styles.block__colContentRow
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.block__col
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.block__col__lottieContainer
                                                        }
                                                    >
                                                        {lottiesToShow.includes(
                                                            i
                                                        ) && (
                                                            <Lottie
                                                                animationData={
                                                                    CheckMarkLottie
                                                                }
                                                                loop={false}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.block__col
                                                    }
                                                >
                                                    {value}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {i === 1 && (
                                <SanityImage
                                    ref={shapeRef}
                                    image={shapeImage}
                                    className={styles.shape}
                                />
                            )}
                            {i === 0 && noteTitle && noteText && (
                                <div className={styles.noteContent}>
                                    <h2 className={styles.noteContent__heading}>
                                        <img
                                            className={
                                                styles.noteContent__headingIcon
                                            }
                                            src={NoteIcon.src}
                                            alt=""
                                        />
                                        <span
                                            className={
                                                styles.noteContent__headingText
                                            }
                                        >
                                            {noteTitle}
                                        </span>
                                    </h2>
                                    <p className={styles.noteContent__text}>
                                        {noteText}
                                    </p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default memo(BursariesEligibility);
