import React, { memo, useRef, useState, useEffect } from "react";
import classnames from "classnames";

import styles from "./BoxHeading.module.scss";

import Lottie from "lottie-react";

import GreaterThanLottie from "../../assets/lottie/greater.json";
import QuestionLottie from "../../assets/lottie/question.json";

const LOTTIE_FILES = {
    "greater-than": GreaterThanLottie,
    "question-mark": QuestionLottie,
};

function BoxHeading({
    className,
    text,
    icon,
    theme = "blue",
    titleSize = "large",
}) {
    const observer = useRef();
    const container = useRef();
    const hasIntersected = useRef(false);
    const [inView, setInView] = useState(false);

    useEffect(() => {
        if (!container.current || !icon) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !hasIntersected.current) {
                        hasIntersected.current = true;
                        setInView(true);
                    }
                });
            },
            { threshold: 0, rootMargin: "0px 0px -25% 0px" }
        );

        observer.current.observe(container.current);

        return () => {
            observer.current?.disconnect();
        };
    }, [icon]);

    return (
        <header
            ref={container}
            className={classnames(
                styles.BoxHeading,
                className,
                {
                    [styles.hasIcon]: icon,
                },
                {
                    [styles[theme]]: theme,
                }
            )}
            data-title-size={titleSize}
        >
            <h1 className={styles.boxHeading__title}>{text}</h1>
            {icon && (
                <div className={styles.boxHeading__iconBox}>
                    <div className={styles.boxHeading__lottieContainer}>
                        {inView && (
                            <Lottie
                                animationData={LOTTIE_FILES[icon]}
                                loop={false}
                            />
                        )}
                    </div>
                </div>
            )}
        </header>
    );
}

export default memo(BoxHeading);
