import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import gsap from "gsap";

import styles from "./TicketPlans.module.scss";
import SanityImage from "../SanityImage/SanityImage";
import Link from "../Link/Link";
import Highlight from "../Highlight/Highlight";

function TicketPlans({
    className,
    graphicBelow,
    ticketTypes,
    hasTopSpacingBox,
}) {
    const planRefs = useRef([]);
    const graphicRef = useRef();
    const innerRef = useRef();
    const containerRef = useRef();
    const graphicContainerRef = useRef();
    const [isAnimated, setIsAnimated] = useState(false);

    const animateIn = () => {
        setIsAnimated(true);
        const ease = "Power4.easeOut";
        const duration = 1.6;

        gsap.fromTo(
            planRefs.current,
            {
                y: "100%",
                autoAlpha: 0,
            },
            {
                stagger: 0.1,
                autoAlpha: 1,
                y: 0,
                ease,
                duration,
            }
        );

        gsap.fromTo(
            graphicRef.current,
            {
                y: 200,
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
                y: 0,
                ease,
                duration,
            }
        );

        gsap.to(graphicContainerRef.current, {
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top top",
                end: "bottom top",
                scrub: 1,
            },
            y: "-10vh",
        });
    };

    useEffect(() => {
        animateIn();
    }, []);

    if (!graphicBelow || !ticketTypes.length) return null;

    return (
        <div
            className={classnames(
                styles.TicketPlans,
                className,
                {
                    [styles.isAnimated]: isAnimated,
                },
                {
                    [styles.hasTopSpacingBox]: hasTopSpacingBox,
                }
            )}
            ref={containerRef}
        >
            <div className={styles.inner} ref={innerRef}>
                <ul
                    className={classnames(styles.plans, [
                        styles[`col-${ticketTypes.length}`],
                    ])}
                >
                    {ticketTypes.map((plan, i) => (
                        <li
                            ref={(ref) => (planRefs.current[i] = ref)}
                            className={classnames(styles.plans__item, {
                                [styles.plans__itemIsHighlighted]:
                                    plan.isHighlighted,
                            })}
                            key={i}
                        >
                            <header className={styles.plans__header}>
                                <h1 className={styles.plans__itemTitle}>
                                    {plan.title}
                                </h1>
                                {plan.price && (
                                    <div className={styles.plans__itemPrice}>
                                        {plan.price}
                                    </div>
                                )}
                            </header>
                            <div className={styles.plans__itemContent}>
                                {plan.itemsIncluded.length > 0 && (
                                    <ul
                                        className={
                                            styles.plans__itemContentList
                                        }
                                    >
                                        {plan.itemsIncluded.map((item, i) => (
                                            <li
                                                className={
                                                    styles.plans__itemContentListItem
                                                }
                                                key={i}
                                            >
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div
                                data-highlight-container
                                className={styles.linkContainer}
                            >
                                <Link link={plan.link} className={styles.link}>
                                    <span className={styles.link__label}>
                                        {plan.link.label}
                                        <Highlight
                                            className={styles.highlight}
                                        />
                                    </span>
                                </Link>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={styles.graphicContainer} ref={graphicContainerRef}>
                <SanityImage
                    ref={graphicRef}
                    className={styles.graphic}
                    image={graphicBelow}
                />
            </div>
        </div>
    );
}

export default memo(TicketPlans);
