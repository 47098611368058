import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./AboutBoxSection.module.scss";

import Cube from "../Cube/Cube";
import RichText from "../RichText/RichText";
import SanityImage from "../SanityImage/SanityImage";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function AboutBoxSection({ className, boxTitle, boxContent, image }) {
    const cubeRef = useRef();
    const imageRef = useRef();
    const container = useRef();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        gsap.killTweensOf([imageRef.current, cubeRef.current]);

        gsap.to(imageRef.current, {
            y: isMobile ? "-10vh" : "-50vh",
            scrollTrigger: {
                trigger: imageRef.current,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.75,
            },
        });

        gsap.to(cubeRef.current, {
            "--box-offset": isMobile ? "2.5rem" : "5rem",
            scrollTrigger: {
                trigger: cubeRef.current,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.75,
            },
        });
    }, [isMobile]);

    if (!boxTitle || !boxContent || !image) return null;

    return (
        <div
            ref={container}
            className={classnames(styles.AboutBoxSection, className)}
        >
            <div className={styles.inner}>
                <SanityImage
                    ref={imageRef}
                    image={image}
                    className={styles.image}
                />
                <Cube
                    ref={cubeRef}
                    className={styles.cube}
                    frontContent={
                        <div className={styles.cubeFront}>
                            <h1 className={styles.heading}>{boxTitle}</h1>
                            <div className={styles.rta}>
                                <RichText content={boxContent.content} />
                            </div>
                        </div>
                    }
                    backContent={<div className={styles.cubeBack} />}
                />
            </div>
        </div>
    );
}

export default memo(AboutBoxSection);
