import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./BursariesHero.module.scss";

import TitleTextBlock from "../TitleTextBlock/TitleTextBlock";
import TitleListBlock from "../TitleListBlock/TitleListBlock";
import SanityImage from "../SanityImage/SanityImage";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function BursariesHero({ className, hero, benefits, images }) {
    const imageRefs = useRef([]);
    const imageContainerRefs = useRef([]);
    const blockRefs = useRef([]);
    const container = useRef();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        const ease = "Power3.easeOut";
        const duration = 1.2;

        gsap.killTweensOf([
            ...imageRefs.current,
            ...blockRefs.current,
            ...imageContainerRefs.current,
        ]);

        gsap.fromTo(
            imageRefs.current,
            {
                y: "10rem",
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                delay: 0.4,
                duration,
                ease,
                stagger: 0.2,
            }
        );

        if (blockRefs.current[0]) {
            gsap.fromTo(
                blockRefs.current,
                {
                    y: "10rem",
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    autoAlpha: 1,
                    delay: 0.4,
                    duration,
                    ease,
                    stagger: 0.2,
                }
            );
        }

        if (!isMobile) {
            gsap.to(imageContainerRefs.current[0], {
                y: "25vh",
                scrollTrigger: {
                    trigger: container.current,
                    start: "top top",
                    end: "bottom top",
                    scrub: 0.75,
                },
            });
        }

        if (isMobile) {
            gsap.to(imageRefs.current[0], {
                rotate: "30deg",
                scrollTrigger: {
                    trigger: imageRefs.current[0],
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 0.75,
                },
            });
        }
    }, [isMobile]);

    if (!hero || !benefits || !images) return null;

    return (
        <div
            className={classnames(styles.BursariesHero, className)}
            ref={container}
        >
            <div className={styles.inner}>
                <div className={styles.heroContainer}>
                    <TitleTextBlock
                        className={styles.heroBlock}
                        data={hero}
                        containerRef={(ref) => {
                            blockRefs.current[0] = ref;
                        }}
                    />
                    <div
                        className={styles.imageTopContainer}
                        ref={(ref) => (imageContainerRefs.current[0] = ref)}
                    >
                        <SanityImage
                            ref={(ref) => (imageRefs.current[0] = ref)}
                            className={styles.imageTop}
                            image={images.imageTop}
                        />
                    </div>
                </div>
                <div
                    ref={(ref) => (blockRefs.current[1] = ref)}
                    className={styles.benefitsContainer}
                >
                    <TitleListBlock schedule={benefits} />
                    <div className={styles.imageEquationContainer}>
                        <SanityImage
                            ref={(ref) => (imageRefs.current[1] = ref)}
                            className={styles.imageEquation}
                            image={images.equation}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(BursariesHero);
