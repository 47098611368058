import React from "react";
import classnames from "classnames";

import CtaSectionContent from "./CtaSectionContent";

import styles from "./CtaSection.module.scss";

const CtaSectionRectShape = React.forwardRef(
    ({ onMouseEnter, onMouseLeave, className, cta }, ref) => {
        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={classnames(className, styles.RectShape)}
                ref={ref}
            >
                <div
                    className={classnames(
                        styles.rectShape__front,
                        styles.rectShape__side
                    )}
                ></div>
                <div
                    className={classnames(
                        styles.rectShape__middle,
                        styles.rectShape__side
                    )}
                >
                    <CtaSectionContent
                        className={styles.rectShape__content}
                        cta={cta}
                    />
                </div>
                <div
                    className={classnames(
                        styles.rectShape__back,
                        styles.rectShape__side
                    )}
                ></div>
                <div
                    className={classnames(
                        styles.rectShape__left,
                        styles.rectShape__side
                    )}
                />
                <div
                    className={classnames(
                        styles.rectShape__right,
                        styles.rectShape__side
                    )}
                />
            </div>
        );
    }
);

export default CtaSectionRectShape;
