import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./GetInvolvedHero.module.scss";

import TitleTextBlock from "../TitleTextBlock/TitleTextBlock";
import VerticalText from "../VerticalText/VerticalText";
import SanityImage from "../SanityImage/SanityImage";
import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function GetInvolvedHero({ className, titleTextBlock, images }) {
    const { isMobile } = useBreakpoint();
    const imageRefs = useRef([]);
    const container = useRef();
    const titleTextBlockRef = useRef();

    const animateIn = () => {
        const duration = 0.8;
        const ease = "Power3.easeOut";

        gsap.fromTo(
            titleTextBlockRef.current,
            {
                y: "20rem",
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                ease,
                duration,
            }
        );

        gsap.fromTo(
            imageRefs.current,
            {
                y: "10rem",
                autoAlpha: 0,
            },
            {
                y: 0,
                ease,
                autoAlpha: 1,
                stagger: 0.2,
                duration,
            }
        );
    };

    useEffect(() => {
        if (!imageRefs.current[0]) return;

        gsap.to(imageRefs.current, {
            y: (i) => {
                if (i === 0) return isMobile ? "-10vh" : "-50vh";
                if (i === 1) return isMobile ? 0 : "-20vh";
                if (i === 2) return isMobile ? "10vh" : "100vh";
            },
            scrollTrigger: {
                trigger: container.current,
                end: window.innerHeight * 4,
                start: 0,
                scrub: 0.75,
            },
        });
    }, [isMobile]);

    useEffect(() => {
        animateIn();
    }, []);

    if (!titleTextBlock || !images) return null;

    return (
        <section
            ref={container}
            className={classnames(styles.GetInvolvedHero, className)}
        >
            <div className={styles.inner}>
                <TitleTextBlock
                    containerRef={titleTextBlockRef}
                    data={titleTextBlock}
                    className={styles.titleTextBlock}
                />

                <div className={styles.images}>
                    <SanityImage
                        image={images.imageTop}
                        className={styles.imageTop}
                        ref={(ref) => (imageRefs.current[0] = ref)}
                    />
                    <SanityImage
                        image={images.imageMiddle}
                        className={styles.imageMiddle}
                        ref={(ref) => (imageRefs.current[1] = ref)}
                    />
                    <SanityImage
                        image={images.imageBottom}
                        className={styles.imageBottom}
                        ref={(ref) => (imageRefs.current[2] = ref)}
                    />
                </div>
            </div>
        </section>
    );
}

export default memo(GetInvolvedHero);
