import React, { memo } from "react";

import HomeHero from "../HomeHero/HomeHero";
import HomeScheduleSection from "../HomeScheduleSection/HomeScheduleSection";
import CtaSection from "../CtaSection/CtaSection";
import FixedVideo from "../FixedVideo/FixedVideo";
import TicketPlans from "../TicketPlans/TicketPlans";
import FaqAccordion from "../FaqAccordion/FaqAccordion";
import GetInvolvedHero from "../GetInvolvedHero/GetInvolvedHero";
import GetInvolvedPartnerHostSection from "../GetInvolvedPartnerHostSection/GetInvolvedPartnerHostSection";
import ContactUsSection from "../ContactUsSection/ContactUsSection";
import AboutHero from "../AboutHero/AboutHero";
import AboutBoxSection from "../AboutBoxSection/AboutBoxSection";
import AboutEdmontonSection from "../AboutEdmontonSection/AboutEdmontonSection";
import FixedNavigationSection from "../FixedNavigationSection/FixedNavigationSection";
import LogosSection from "../LogosSection/LogosSection";
import Gallery from "../Gallery/Gallery";
import BursariesHero from "../BursariesHero/BursariesHero";
import BursariesAbout from "../BursariesAbout/BursariesAbout";
import BursariesEligibility from "../BursariesEligibility/BursariesEligibility";
import BursariesDiversity from "../BursariesDiversity/BursariesDiversity";
import ScheduleSection from "../ScheduleSection/ScheduleSection";
import SpeakersSection from "../SpeakersSection/SpeakersSection";
import RichTextSection from "../RichTextSection/RichTextSection";

const MODULES = {
    homeHero: HomeHero,
    homeScheduleSection: HomeScheduleSection,
    ctaSection: CtaSection,
    fixedVideo: FixedVideo,
    ticketPlans: TicketPlans,
    faqAccordion: FaqAccordion,
    getInvolvedHero: GetInvolvedHero,
    getInvolvedPartnerHostSection: GetInvolvedPartnerHostSection,
    contactUsSection: ContactUsSection,
    aboutHero: AboutHero,
    aboutBoxSection: AboutBoxSection,
    aboutEdmontonSection: AboutEdmontonSection,
    fixedNavigationSection: FixedNavigationSection,
    logosSection: LogosSection,
    gallery: Gallery,
    bursariesHero: BursariesHero,
    bursariesAbout: BursariesAbout,
    bursariesEligibility: BursariesEligibility,
    bursariesDiversity: BursariesDiversity,
    scheduleSection: ScheduleSection,
    speakersSection: SpeakersSection,
    richTextSection: RichTextSection,
};

function PageContent({ content }) {
    if (!content) return null;

    return (
        <div>
            {content.map((module, i) => {
                if (!MODULES[module._type]) {
                    console.warn("No module found with type ", module._type);
                    return null;
                }
                const ModuleComponent = MODULES[module._type];
                return (
                    <ModuleComponent key={`${i}_${module._key}`} {...module} />
                );
            })}
        </div>
    );
}

export default memo(PageContent);
