import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";

import styles from "./TitleTextBlock.module.scss";

import RichText from "../RichText/RichText";
import BoxHeading from "../BoxHeading/BoxHeading";

import useStore from "../../store";

const TitleTextBlock = ({ data, className, containerRef }) => {
    const observer = useRef();
    const trackStopPositionRef = useRef();
    const [inView, setInView] = useState(false);
    const windowWidth = useStore((state) => state.windowWidth);
    const windowHeight = useStore((state) => state.windowHeight);
    const _containerRef = useRef();

    useEffect(() => {
        _containerRef.current;

        if (!trackStopPositionRef.current || !_containerRef.current) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        const setProperty = () => {
            if (_containerRef.current && trackStopPositionRef.current) {
                _containerRef.current.style.setProperty(
                    "--offset-distance",
                    `${trackStopPositionRef.current.offsetTop}px`
                );
            }
        };

        setProperty();

        observer.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    setProperty();

                    setInView(
                        !entry.isIntersecting && entry.boundingClientRect.y < 0
                    );
                });
            },
            { threshold: 0, rootMargin: `0px 0px 100% 0px` }
        );

        observer.current.observe(trackStopPositionRef.current);

        return () => {
            observer.current?.disconnect();
        };
    }, [windowWidth, windowHeight]);

    return (
        <div
            ref={(ref) => {
                _containerRef.current = ref;
                if (containerRef) {
                    if (typeof containerRef === "function") {
                        return containerRef(ref);
                    } else {
                        containerRef.current = ref;
                    }
                }
            }}
            className={classnames(styles.TitleTextBlock, className, {
                [styles.hasBorder]: data.hasBorder,
            })}
        >
            <BoxHeading
                className={classnames(
                    styles.boxHeading,
                    {
                        [styles.sticky]: data.hasStickyHeader,
                    },
                    {
                        [styles.stuck]: inView,
                    }
                )}
                text={data.title}
                icon={data.icon === "none" ? null : data.icon}
                theme={data.theme}
                titleSize={data.titleSize}
            />
            <div className={styles.content}>
                <div
                    className={styles.trackStopPosition}
                    ref={trackStopPositionRef}
                />
                <RichText
                    content={data.content.content}
                    contentSize={data.contentSize}
                />
            </div>
        </div>
    );
};

export default memo(TitleTextBlock);
