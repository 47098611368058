import React, { memo, useEffect, useState, useRef } from "react";
import { PortableText } from "@portabletext/react";
import classnames from "classnames";
import gsap from "gsap";

import styles from "./FaqAccordion.module.scss";

import AccordionTriangle from "../../assets/svgs/accordion-triangle.svg";

function FaqAccordion({ className, items }) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const answerContainerRefs = useRef([]);
    const answerRefs = useRef([]);

    const animateOpen = (i) => {
        answerContainerRefs.current.forEach((el, index) => {
            const isOpen = i === index;
            const elContentHeight = answerRefs.current[index].offsetHeight;
            gsap.to(el, {
                height: isOpen ? elContentHeight : 0,
                duration: 0.2,
            });
        });
    };

    useEffect(() => {
        animateOpen(activeIndex);
    }, [activeIndex]);

    if (!items.length) return null;

    return (
        <section className={classnames(styles.FaqAccordion, className)}>
            <ul className={styles.faqAccordion__list}>
                {items.map((item, i) => (
                    <li
                        className={classnames(styles.faqAccordion__listItem, {
                            [styles.active]: i === activeIndex,
                        })}
                        key={i}
                    >
                        <div
                            onClick={() => {
                                setActiveIndex(i === activeIndex ? -1 : i);
                            }}
                            className={styles.faqAccordion__listItemQuestion}
                        >
                            <span
                                className={
                                    styles.faqAccordion__listItemQuestionText
                                }
                            >
                                {item.question}
                            </span>
                            <AccordionTriangle
                                className={styles.faqAccordion__listItemIcon}
                            />
                        </div>
                        <div
                            className={styles.faqAccordion__listItemAnswer}
                            ref={(ref) =>
                                (answerContainerRefs.current[i] = ref)
                            }
                        >
                            <div
                                className={
                                    styles.faqAccordion__listItemAnswerInner
                                }
                                ref={(ref) => (answerRefs.current[i] = ref)}
                            >
                                <div
                                    className={
                                        styles.faqAccordion__listItemAnswerWidth
                                    }
                                >
                                    <PortableText value={item.answer.content} />
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default memo(FaqAccordion);
