import React, { memo, useEffect, useRef } from "react";

import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useInView } from "react-intersection-observer";

import useBreakpoint from "../../utils/hooks/use-breakpoint";
import SanityImage from "../SanityImage/SanityImage";
import TitleListBlock from "../TitleListBlock/TitleListBlock";
import Cube from "../Cube/Cube";

import styles from "./HomeScheduleSection.module.scss";

import Polygon from "../../assets/svgs/polygon.svg";

import useStore from "../../store";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function HomeScheduleSection({ schedule, textBox, parallaxImages, className }) {
    const { ref: inViewRef, inView } = useInView();
    const masterRef = useRef();
    const scheduleRef = useRef();
    const scheduleTransitionRef = useRef();
    const boxRef = useRef();
    const polygonRef = useRef();
    const boxColorRef = useRef();
    const textBoxContainerRef = useRef();
    const tl = useRef();
    const boxTl = useRef();
    const scrollTrigger = useRef();
    const parallaxImageRefs = useRef([]);
    const homeIsFinishedAnimating = useStore(
        (state) => state.homeIsFinishedAnimating
    );
    const { isMobile } = useBreakpoint();

    const desktopScrollTriggerAnimation = () => {
        if (scrollTrigger.current) scrollTrigger.current.kill();

        if (
            !masterRef.current ||
            !polygonRef.current ||
            !parallaxImageRefs.current[0] ||
            !parallaxImageRefs.current[1] ||
            !parallaxImageRefs.current[2] ||
            !scheduleRef.current ||
            !boxRef.current ||
            !boxColorRef.current
        ) {
            return;
        }

        tl.current = gsap.timeline();
        const duration = 1.4;
        const ease = "Power2.easeInOut";

        tl.current.fromTo(
            polygonRef.current,
            {
                rotate: "45deg",
                y: 0,
                autoAlpha: 1,
            },
            {
                y: -window.innerHeight * 1.75,
                rotate: "0deg",
                duration: duration,
                ease: "linear",
            },
            0
        );

        tl.current.to(
            parallaxImageRefs.current[0],
            {
                y: "-30vh",
                autoAlpha: 0,
                duration,
                rotate: "10deg",
                scale: 1.1,
                ease: "linear",
            },
            0
        );

        tl.current.to(
            parallaxImageRefs.current[1],
            {
                y: "-50vh",
                autoAlpha: 0,
                duration,
                rotate: "-15deg",
                scale: 1.05,
                ease: "linear",
            },
            0
        );

        tl.current.from(
            parallaxImageRefs.current[2],
            {
                y: "100%",
                autoAlpha: 0,
                duration,
                rotate: "-15deg",
                scale: 0.95,
                ease: "Power3.easeOut",
            },
            0.5
        );

        tl.current.from(
            scheduleRef.current,
            {
                y: window.innerHeight,
                duration,
                ease,
            },
            0.75
        );

        tl.current.to(
            boxRef.current,
            {
                "--box-offset": "12rem",
                duration: duration + 0.75,
                ease,
            },
            0
        );

        tl.current.to(
            boxColorRef.current,
            {
                autoAlpha: 0,
                duration,
                ease: "linear",
            },
            0.5
        );

        if (masterRef.current && tl.current) {
            scrollTrigger.current = new ScrollTrigger({
                trigger: masterRef.current,
                start: "top top",
                end: `bottom bottom-=${window.innerHeight * 2}`,
                pin: true,
                scrub: 0.7,
                animation: tl.current,
            });
        }
    };

    const mobileScrollTriggerAnimation = () => {
        if (!boxRef.current || !textBoxContainerRef.current) {
            return;
        }

        const duration = 1.4;
        const ease = "Power2.easeInOut";

        if (boxTl.current) boxTl.current.kill();
        if (scrollTrigger.current) scrollTrigger.current.kill();

        boxTl.current = gsap.timeline();

        boxTl.current.to(
            boxRef.current,
            {
                "--box-offset": "3rem",
                // "--box-angle": "-75deg",
                // "--box-y-offset": "4.5rem",
                duration: duration,
                ease,
            },
            0
        );

        boxTl.current.to(
            boxRef.current,
            {
                x: "1.5rem",
                duration: duration,
                ease,
            },
            0
        );

        scrollTrigger.current = new ScrollTrigger({
            trigger: textBoxContainerRef.current,
            start: "top top",
            end: `bottom bottom-=${window.innerHeight * 2}`,
            pin: true,
            scrub: 0.7,
            // markers: true,
            animation: boxTl.current,
        });
    };

    useEffect(() => {
        if (!homeIsFinishedAnimating) return;
        if (tl.current) return;

        if (scrollTrigger.current) {
            scrollTrigger.current?.kill();
        }

        if (tl.current) tl.current.kill();

        if (boxTl.current) boxTl.current.kill();

        if (isMobile) {
            mobileScrollTriggerAnimation();
        } else {
            desktopScrollTriggerAnimation();
        }
    }, [homeIsFinishedAnimating, isMobile]);

    // Cleanup scroll Trigger
    useEffect(() => {
        return () => {
            if (scrollTrigger.current) scrollTrigger.current?.kill();
            if (tl.current) tl.current.kill();
            if (boxTl.current) boxTl.current.kill();
        };
    }, []);

    const cubeContent = (
        <div ref={textBoxContainerRef} className={styles.textBoxContainer}>
            <Cube
                frontContent={
                    <div className={styles.textBox__frontText}>
                        <h1 className={styles.textBox__frontHeading}>
                            {textBox.title}
                        </h1>
                        <p className={styles.textBox__frontDescription}>
                            {textBox.description}
                        </p>
                    </div>
                }
                backContent={
                    <>
                        <SanityImage
                            preload={true}
                            image={textBox.image}
                            className={styles.textBox__backImage}
                        />
                        <div
                            className={styles.textBox__backColor}
                            ref={boxColorRef}
                        />
                    </>
                }
                ref={boxRef}
            />
        </div>
    );

    if (!schedule || !textBox || !parallaxImages) return null;

    return (
        <div
            ref={masterRef}
            className={classnames(styles.HomeScheduleSection, className)}
        >
            <div className={styles.inViewTrigger} ref={inViewRef} />
            {isMobile ? (
                <>
                    <div className={styles.mobileTextBoxContainer}>
                        {cubeContent}
                    </div>
                    <div className={styles.mobileScheduleContainer}>
                        <TitleListBlock
                            ref={scheduleRef}
                            schedule={schedule}
                            scheduleTransitionRef={scheduleTransitionRef}
                            className={classnames(
                                styles.schedule,
                                styles.mobile
                            )}
                        />
                    </div>
                </>
            ) : (
                <>
                    <TitleListBlock
                        ref={scheduleRef}
                        schedule={schedule}
                        scheduleTransitionRef={scheduleTransitionRef}
                        className={classnames(styles.schedule, styles.desktop)}
                    />
                    <div className={styles.polygon} ref={polygonRef}>
                        <Polygon />
                    </div>
                    {cubeContent}
                    <div className={styles.textBox__parallaxImages}>
                        <SanityImage
                            preload={true}
                            image={parallaxImages.image1}
                            className={classnames(
                                styles.parallaxImage,
                                styles.parallaxImage1
                            )}
                            ref={(ref) => (parallaxImageRefs.current[0] = ref)}
                        />
                        <SanityImage
                            preload={true}
                            image={parallaxImages.image2}
                            className={classnames(
                                styles.parallaxImage,
                                styles.parallaxImage2
                            )}
                            ref={(ref) => (parallaxImageRefs.current[1] = ref)}
                        />
                        <SanityImage
                            preload={true}
                            image={parallaxImages.image3}
                            className={classnames(
                                styles.parallaxImage,
                                styles.parallaxImage3
                            )}
                            ref={(ref) => (parallaxImageRefs.current[2] = ref)}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

// const Cube = React.forwardRef(
//     ({ textBox, boxColorRef, textBoxContainerRef, className }, ref) => {
//         return (
//             <div className={styles.textBoxContainer} ref={textBoxContainerRef}>
//                 <div ref={ref} className={styles.textBox}>
//                     <div className={styles.textBox__front}>
//                         <div className={styles.textBox__frontText}>
//                             <h1 className={styles.textBox__frontHeading}>
//                                 {textBox.title}
//                             </h1>
//                             <p className={styles.textBox__frontDescription}>
//                                 {textBox.description}
//                             </p>
//                         </div>
//                     </div>
//                     <div className={styles.textBox__back}>
//                         <SanityImage
//                             preload={true}
//                             image={textBox.image}
//                             className={styles.textBox__backImage}
//                         />
//                         <div
//                             className={styles.textBox__backColor}
//                             ref={boxColorRef}
//                         />
//                     </div>
//                     <div className={styles.textBox__left}></div>
//                     <div className={styles.textBox__right}></div>
//                 </div>
//             </div>
//         );
//     }
// );

export default memo(HomeScheduleSection);
