import React, { memo, useRef, useEffect } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./AboutEdmontonSection.module.scss";

import TitleTextBlock from "../TitleTextBlock/TitleTextBlock";
import RectangleText from "../RectangleText/RectangleText";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function AboutEdmontonSection({
    className,
    intro,
    howToGetThere,
    whereToStay,
    rectangeText,
    rectangleTextLink,
}) {
    const whereToStayBlockRef = useRef();
    const rectangleRef = useRef();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (!whereToStayBlockRef.current || !rectangleRef.current) return;

        gsap.killTweensOf([whereToStayBlockRef.current, rectangleRef.current]);

        if (!isMobile) {
            gsap.to(whereToStayBlockRef.current, {
                y: "-50vh",
                scrollTrigger: {
                    trigger: whereToStayBlockRef.current,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 0.75,
                },
            });

            gsap.to(rectangleRef.current, {
                x: "20rem",
                scrollTrigger: {
                    trigger: rectangleRef.current,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 0.75,
                },
            });
        }
    }, [isMobile]);

    if (
        !intro ||
        !howToGetThere ||
        !whereToStay ||
        !rectangeText ||
        !rectangleTextLink
    ) {
        return null;
    }

    return (
        <div className={classnames(styles.AboutEdmontonSection, className)}>
            <div className={styles.inner}>
                <div className={styles.col1}>
                    <TitleTextBlock
                        className={styles.introBlock}
                        data={intro}
                    />
                    <TitleTextBlock
                        className={styles.howToGetThereBlock}
                        data={howToGetThere}
                    />
                    <RectangleText
                        ref={rectangleRef}
                        className={styles.rectangleBlock}
                        text={rectangeText}
                        link={rectangleTextLink}
                    />
                </div>
                <div className={styles.col2}>
                    <TitleTextBlock
                        className={styles.whereToStayBlock}
                        data={whereToStay}
                        containerRef={whereToStayBlockRef}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(AboutEdmontonSection);
