import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./BursariesDiversity.module.scss";

import TitleListBlock from "../TitleListBlock/TitleListBlock";
import SanityImage from "../SanityImage/SanityImage";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function BursariesDiversity({
    className,
    title,
    description,
    listContent,
    equationImage,
}) {
    const { isMobile } = useBreakpoint();
    const imageRef = useRef();

    useEffect(() => {
        if (isMobile) return;

        gsap.from(imageRef.current, {
            x: "-30rem",
            scrollTrigger: {
                trigger: imageRef.current,
                start: "top bottom",
                end: "bottom top",
                scrub: 1,
            },
        });
    }, [isMobile]);

    if (!title || !description || !listContent || !equationImage) {
        return null;
    }

    return (
        <div className={classnames(styles.BursariesDiversity, className)}>
            <div className={styles.inner}>
                <div className={styles.headingDescriptionContainer}>
                    <SanityImage
                        image={equationImage}
                        className={styles.image}
                        ref={imageRef}
                    />
                    <h1 className={styles.heading}>{title}</h1>
                    <p className={styles.description}>{description}</p>
                </div>
                <TitleListBlock
                    schedule={listContent}
                    className={styles.listBlock}
                />
            </div>
        </div>
    );
}

export default memo(BursariesDiversity);
