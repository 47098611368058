import React, { memo, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";

import styles from "./FixedVideo.module.scss";

import useStore from "../../store";

function FixedVideo({ videoThumbnail, vimeoId, className }) {
    const [metaLoaded, setMetaLoaded] = useState();
    const videoThumbContainerRef = useRef();
    const setFixedVideoOpen = useStore((state) => state.setFixedVideoOpen);
    const fixedVideoOpen = useStore((state) => state.fixedVideoOpen);
    const homeIsFinishedAnimating = useStore(
        (state) => state.homeIsFinishedAnimating
    );

    useEffect(() => {
        if (!homeIsFinishedAnimating || !metaLoaded) return;

        gsap.fromTo(
            videoThumbContainerRef.current,
            {
                y: 50,
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration: 1.2,
                ease: "Power3.easeOut",
            }
        );
    }, [homeIsFinishedAnimating, metaLoaded]);

    return (
        <div className={classnames(styles.FixedVideo, className)}>
            {homeIsFinishedAnimating && (
                <>
                    <div
                        ref={videoThumbContainerRef}
                        className={styles.videoThumb}
                        onClick={() => {
                            setFixedVideoOpen(true);
                        }}
                    >
                        <video
                            src={videoThumbnail.asset.url}
                            loop
                            playsInline
                            muted="muted"
                            autoPlay="autoplay"
                            onLoadedMetadata={() => {
                                setMetaLoaded(true);
                            }}
                        ></video>
                    </div>
                    {fixedVideoOpen && (
                        <div className={styles.videoPopout}>
                            <div
                                onClick={() => {
                                    setFixedVideoOpen(false);
                                }}
                                className={styles.videoPopoutClose}
                            >
                                [Close]
                            </div>
                            <div
                                className={styles.videoPopoutBg}
                                onClick={() => {
                                    setFixedVideoOpen(false);
                                }}
                            />
                            <iframe
                                src={`https://player.vimeo.com/video/${vimeoId}?h=53f87ea1a3&autoplay=1&title=0&byline=0&portrait=0&badge=0`}
                                className={styles.videoPopoutIframe}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullscreen
                            ></iframe>
                            {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default memo(FixedVideo);
