import React, { memo } from "react";
import classnames from "classnames";

import styles from "./TitleListBlock.module.scss";

const TitleListBlock = React.forwardRef(
    ({ schedule, scheduleTransitionRef, className }, ref) => {
        return (
            <aside
                ref={ref}
                className={classnames(styles.titleListBlock, className, {
                    [styles[`theme-${schedule.theme}`]]: schedule.theme,
                })}
            >
                <div
                    ref={scheduleTransitionRef}
                    className={styles.titleListBlock__transitionContainer}
                >
                    <h1 className={styles.titleListBlock__title}>
                        {schedule.title}
                    </h1>
                    <ol className={styles.titleListBlock__list}>
                        {schedule.itinerary.map((item, i) => (
                            <li
                                key={i}
                                className={styles.titleListBlock__listItem}
                            >
                                <span
                                    className={
                                        styles.titleListBlock__listItem__number
                                    }
                                >
                                    {i + 1}
                                </span>
                                <span
                                    className={
                                        styles.titleListBlock__listItem__title
                                    }
                                >
                                    {item}
                                </span>
                            </li>
                        ))}
                    </ol>
                    <footer className={styles.titleListBlock__footer}>
                        <div className={styles.titleListBlock__datesYear}>
                            <div className={styles.titleListBlock__dates}>
                                {schedule.date}
                            </div>
                            <div className={styles.titleListBlock__year}>
                                {schedule.year}
                            </div>
                        </div>
                        <div className={styles.titleListBlock__location}>
                            {schedule.location}
                        </div>
                    </footer>
                </div>
            </aside>
        );
    }
);

export default memo(TitleListBlock);
