import React, { memo, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./SpeakersSection.module.scss";

import { lerp } from "../../utils";

import AccordionTriangle from "../../assets/svgs/accordion-triangle.svg";
import SanityImage from "../SanityImage/SanityImage";
import useBreakpoint from "../../utils/hooks/use-breakpoint";

const LERP = 0.2;

function SpeakersSection({ className, speakersData, title, images }) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const answerContainerRefs = useRef([]);
    const answerRefs = useRef([]);
    const raf = useRef(null);
    const hoverImage = useRef();
    const targetValues = useRef({ x: 0, y: 0 });
    const currentValues = useRef({ x: 0, y: 0 });
    const [speakerHovering, setSpeakerHovering] = useState(null);
    const [isHovering, setIsHovering] = useState(false);
    const { isMobile } = useBreakpoint();

    const rafAnimate = () => {
        raf.current = requestAnimationFrame(rafAnimate);

        if (currentValues.current.x === 0 && currentValues.current.y === 0) {
            currentValues.current = {
                x: targetValues.current.x,
                y: targetValues.current.y,
            };
        } else {
            currentValues.current = {
                x: lerp(currentValues.current.x, targetValues.current.x, LERP),
                y: lerp(currentValues.current.y, targetValues.current.y, LERP),
            };
        }

        gsap.set(hoverImage.current, {
            x: currentValues.current.x,
            y: currentValues.current.y - hoverImage.current.offsetHeight,
        });
    };

    const animatInOutHoverImage = (isAnimateIn = true) => {
        gsap.killTweensOf(hoverImage.current);

        if (isAnimateIn) {
            gsap.set(hoverImage.current, {
                x: targetValues.current.x,
                y: targetValues.current.y - hoverImage.current.offsetHeight,
            });
        }

        gsap.to(hoverImage.current, {
            scale: isAnimateIn ? 1 : 0,
            duration: 0.2,
            delay: 0.03,
            ease: "Power3.easeOut",
        });
    };

    useEffect(() => {
        if (raf.current) {
            cancelAnimationFrame(raf.current);
            raf.current = null;
        }

        if (!isMobile) {
            animatInOutHoverImage(isHovering);
        }

        if (isHovering && !isMobile) {
            rafAnimate();
        }
    }, [isHovering, isMobile]);

    useEffect(() => {
        const handleMouseOver = (e) => {
            const xCoord = e.changedTouches?.length
                ? e.changedTouches[0]?.clientX
                : e.clientX;
            const yCoord = e.changedTouches?.length
                ? e.changedTouches[0]?.clientY
                : e.clientY;
            targetValues.current.x = xCoord;
            targetValues.current.y = yCoord;
        };

        document.addEventListener("mousemove", handleMouseOver);

        return () => {
            document.removeEventListener("mousemove", handleMouseOver);
        };
    }, []);

    const animateOpen = (i) => {
        answerContainerRefs.current.forEach((el, index) => {
            const isOpen = i === index;
            const elContentHeight = answerRefs.current[index].offsetHeight;
            gsap.to(el, {
                height: isOpen ? elContentHeight : 0,
                duration: 0.2,
                onComplete: () => {
                    ScrollTrigger.refresh();
                },
            });
        });
    };

    useEffect(() => {
        animateOpen(activeIndex);
    }, [activeIndex]);

    if (!speakersData?.length) return null;

    return (
        <>
            <div ref={hoverImage} className={styles.hoverImage}>
                <img
                    src={
                        speakerHovering?.picture ||
                        speakerHovering?.logo_company
                    }
                />
            </div>
            <section className={classnames(styles.SpeakersSection, className)}>
                <h1 className={styles.title}>{title}</h1>
                <div className={styles.speakersSection__listContainer}>
                    <SanityImage
                        image={images.topImage}
                        className={styles.speakersSection__topImage}
                    />
                    <SanityImage
                        image={images.bottomImage}
                        className={styles.speakersSection__bottomImage}
                    />
                    <ul className={styles.speakersSection__list}>
                        {speakersData.map((speaker, i) => {
                            if (!speaker.first_name) {
                                return null;
                            }

                            return (
                                <li
                                    className={classnames(
                                        styles.speakersSection__listItem,
                                        {
                                            [styles.active]: i === activeIndex,
                                        },
                                        {
                                            [styles.noPicture]:
                                                !speaker.picture &&
                                                !speaker?.logo_company,
                                        },
                                        {
                                            [styles.clickable]:
                                                (speaker.picture &&
                                                    !isMobile) ||
                                                speaker.logo_company ||
                                                speaker.about,
                                        }
                                    )}
                                    key={i}
                                >
                                    <div
                                        onClick={() => {
                                            setActiveIndex(
                                                i === activeIndex ? -1 : i
                                            );
                                            setIsHovering(false);
                                        }}
                                        className={classnames(
                                            styles.speakersSection__listItemQuestion
                                        )}
                                        onMouseEnter={() => {
                                            if (
                                                (speaker.picture ||
                                                    speaker.logo_company) &&
                                                activeIndex !== i
                                            ) {
                                                setSpeakerHovering(speaker);
                                                setIsHovering(true);
                                            } else {
                                                setIsHovering(false);
                                            }
                                        }}
                                        onMouseLeave={() => {
                                            setIsHovering(false);
                                        }}
                                    >
                                        {(speaker.picture ||
                                            speaker.logo_company) && (
                                            <div
                                                className={
                                                    styles.speakersSection__listItemQuestionImageContainer
                                                }
                                            >
                                                <img
                                                    src={
                                                        speaker.picture ||
                                                        speaker.logo_company
                                                    }
                                                    alt={`${
                                                        speaker.first_name
                                                    } ${
                                                        speaker.last_name
                                                            ? speaker.last_name
                                                            : ""
                                                    }`}
                                                    className={
                                                        styles.speakersSection__listItemQuestionImage
                                                    }
                                                />
                                            </div>
                                        )}
                                        <span
                                            className={
                                                styles.speakersSection__listItemQuestionText
                                            }
                                        >
                                            {speaker.first_name}{" "}
                                            {speaker.last_name
                                                ? speaker.last_name
                                                : ""}
                                        </span>
                                        <AccordionTriangle
                                            className={
                                                styles.speakersSection__listItemIcon
                                            }
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.speakersSection__listItemAnswer
                                        }
                                        ref={(ref) =>
                                            (answerContainerRefs.current[i] =
                                                ref)
                                        }
                                    >
                                        <div
                                            className={
                                                styles.speakersSection__listItemAnswerInner
                                            }
                                            ref={(ref) =>
                                                (answerRefs.current[i] = ref)
                                            }
                                        >
                                            {(speaker.picture ||
                                                speaker.logo_company) &&
                                                !isMobile && (
                                                    <div
                                                        className={
                                                            styles.speakersSection__listItemPicture
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                speaker.picture ||
                                                                speaker.logo_company
                                                            }
                                                            alt={`${
                                                                speaker.first_name
                                                            } ${
                                                                speaker.last_name
                                                                    ? speaker.last_name
                                                                    : ""
                                                            }`}
                                                            className={
                                                                styles.speakersSection__listItemPictureImg
                                                            }
                                                        />
                                                    </div>
                                                )}

                                            <div
                                                className={
                                                    styles.speakersSection__listItemAbout
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: speaker.about
                                                        ? speaker.about
                                                        : "",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </section>
        </>
    );
}

export default memo(SpeakersSection);
