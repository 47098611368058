import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import axios from "axios";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./ContactUsSection.module.scss";

import Highlight from "../Highlight/Highlight";

export const EMAIL_RECIPIENTS = {
    partnerships: "Partnerships",
    communityEvent: "Community Event",
    generalInquiries: "General Inquiries",
};

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function ContactUsSection({ className, title }) {
    const [activeRecipient, setActiveRecipient] = useState(
        Object.keys(EMAIL_RECIPIENTS)[0]
    );
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const titleRef = useRef();
    const formRef = useRef();

    useEffect(() => {
        if (!formRef.current) return;

        gsap.from(formRef.current, {
            y: "20rem",
            scrollTrigger: {
                trigger: formRef.current,
                start: "top bottom",
                end: "50% bottom",
                scrub: 0.75,
            },
        });
    }, []);

    const validateForm = () => {
        const errors = [];
        if (!name) errors.push("Name is required");
        if (
            !String(email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )
        ) {
            errors.push("Email is invalid");
        }
        if (!message) errors.push("Message is required");

        setErrors(errors);
        return errors;
    };

    const handleNameOnChange = (e) => {
        setErrors([]);
        const { value } = e.target;
        setName(value);
    };

    const handleEmailOnChange = (e) => {
        setErrors([]);
        const { value } = e.target;
        setEmail(value);
    };

    const handleMessageOnChange = (e) => {
        setErrors([]);
        const { value } = e.target;
        setMessage(value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (isSuccess) return;
    };

    const handleRecipientClick = (val) => {
        setActiveRecipient(val);
    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();

        if (isSuccess) return;

        const errs = validateForm();
        if (!errs.length) {
            setIsLoading(true);

            try {
                const resp = await axios.post("/api/submit-contact", {
                    recipientType: activeRecipient,
                    name,
                    email,
                    message,
                });

                if (resp.status === 200) {
                    setIsSuccess(true);
                }
            } catch (err) {
                console.log(err);
            }

            setIsLoading(false);
        }
    };

    return (
        <section
            className={classnames(styles.ContactUsSection, className, {
                [styles.success]: isSuccess,
            })}
        >
            <div className={styles.inner}>
                <h1 ref={titleRef} className={styles.title}>
                    {title ? title : "Contact Us"}
                </h1>
                <form
                    ref={formRef}
                    className={styles.form}
                    onSubmit={handleFormSubmit}
                >
                    {isSuccess && (
                        <div className={styles.successMessage}>
                            Message Sent!
                        </div>
                    )}
                    <div className={styles.formInner}>
                        <div className={styles.buttons}>
                            {Object.keys(EMAIL_RECIPIENTS).map(
                                (recipientType, i) => (
                                    <div
                                        role="button"
                                        key={i}
                                        onClick={() => {
                                            handleRecipientClick(recipientType);
                                        }}
                                        className={classnames(
                                            styles.recipientButton,
                                            {
                                                [styles.active]:
                                                    activeRecipient ===
                                                    recipientType,
                                            }
                                        )}
                                        data-highlight-container
                                    >
                                        <span
                                            className={
                                                styles.recipientButtonText
                                            }
                                        >
                                            {Object.values(EMAIL_RECIPIENTS)[i]}
                                            {activeRecipient !==
                                                recipientType && (
                                                <Highlight
                                                    className={styles.highlight}
                                                />
                                            )}
                                        </span>
                                    </div>
                                )
                            )}
                        </div>
                        <div className={styles.fields}>
                            <div className={styles.fieldsTop}>
                                <div className={styles.fieldContainer}>
                                    <label htmlFor="name">Name</label>
                                    <input
                                        onChange={handleNameOnChange}
                                        value={name}
                                        type="text"
                                        name="name"
                                        id="name"
                                    />
                                </div>
                                <div className={styles.fieldContainer}>
                                    <label htmlFor="email">Email</label>
                                    <input
                                        onChange={handleEmailOnChange}
                                        value={email}
                                        type="email"
                                        name="email"
                                        id="email"
                                    />
                                </div>
                            </div>
                            <div className={styles.fieldContainer}>
                                <label htmlFor="message">Message</label>
                                <textarea
                                    onChange={handleMessageOnChange}
                                    value={message}
                                    rows="5"
                                    name="message"
                                    id="message"
                                />
                            </div>
                            {errors.length > 0 &&
                                errors.map((error, i) => (
                                    <p className={styles.error} key={i}>
                                        {error}
                                    </p>
                                ))}
                            {!isSuccess && (
                                <button
                                    className={styles.sendButton}
                                    onClick={handleSubmitClick}
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Sending..." : "Send"}
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default memo(ContactUsSection);
