import React, { memo, useRef, useEffect } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import TitleTextBlock from "../TitleTextBlock/TitleTextBlock";
import RectangleText from "../RectangleText/RectangleText";
import SanityImage from "../SanityImage/SanityImage";
import useBreakpoint from "../../utils/hooks/use-breakpoint";

import styles from "./AboutHero.module.scss";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function AboutHero({
    className,
    introTitleTextBlock,
    rectangeText,
    aboutTitleTextBlock,
    images,
}) {
    const { isMobile } = useBreakpoint();
    const introBlockRef = useRef();
    const container = useRef();
    const imageRefs = useRef([]);
    const imageContainerRefs = useRef([]);
    const aboutTextBlockRef = useRef();
    const rectangleBox = useRef();

    const animateIn = () => {
        if (!introBlockRef.current) return;

        const ease = "Power3.easeOut";
        const duration = 1.2;

        gsap.fromTo(
            introBlockRef.current,
            {
                y: "30rem",
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration,
                ease,
            }
        );

        if (!isMobile) {
            gsap.fromTo(
                imageContainerRefs.current,
                {
                    y: "20rem",
                    autoAlpha: 0,
                },
                {
                    y: 0,
                    stagger: 0.15,
                    autoAlpha: 1,
                    duration,
                    ease,
                }
            );
        }
    };

    useEffect(() => {
        if (!imageRefs.current[0]) return;

        gsap.killTweensOf([
            ...imageRefs.current,
            aboutTextBlockRef.current,
            rectangleBox.current,
        ]);

        gsap.to(imageRefs.current, {
            y: (i) => {
                if (i === 1) return isMobile ? "-10vh" : "-30vh";
                if (i === 2) return isMobile ? "-20vh" : "-60vh";
            },
            scrollTrigger: {
                trigger: container.current,
                end: window.innerHeight * 4,
                start: 0,
                scrub: 0.75,
            },
        });

        if (!isMobile) {
            gsap.to(aboutTextBlockRef.current, {
                y: "-30vh",
                scrollTrigger: {
                    trigger: container.current,
                    end: window.innerHeight * 4,
                    start: 0,
                    scrub: 0.75,
                },
            });
        }

        if (!isMobile) {
            gsap.from(rectangleBox.current, {
                x: "-15rem",
                scrollTrigger: {
                    trigger: rectangleBox.current,
                    end: "bottom top",
                    start: "top-=80px bottom",
                    scrub: 0.75,
                    ease: "linear",
                },
            });
        }
    }, [isMobile]);

    useEffect(() => {
        animateIn();
    }, []);

    if (
        !introTitleTextBlock ||
        !rectangeText ||
        !aboutTitleTextBlock ||
        !images
    )
        return null;

    return (
        <div
            ref={container}
            className={classnames(styles.AboutHero, className)}
        >
            <div className={styles.inner}>
                <div
                    ref={introBlockRef}
                    className={styles.introTextBlockContainer}
                >
                    <TitleTextBlock
                        className={styles.introBlock}
                        data={introTitleTextBlock}
                    />
                    <div className={styles.images}>
                        <div
                            ref={(ref) => (imageContainerRefs.current[0] = ref)}
                        >
                            <SanityImage
                                image={images.tallImage}
                                className={styles.imageTall}
                                ref={(ref) => (imageRefs.current[0] = ref)}
                            />
                        </div>
                        <div
                            ref={(ref) => (imageContainerRefs.current[1] = ref)}
                        >
                            <SanityImage
                                image={images.linesImage}
                                className={styles.imageLines}
                                ref={(ref) => (imageRefs.current[1] = ref)}
                            />
                        </div>
                        <div
                            ref={(ref) => (imageContainerRefs.current[2] = ref)}
                        >
                            <SanityImage
                                image={images.diamondImage}
                                className={styles.imageDiamond}
                                ref={(ref) => (imageRefs.current[2] = ref)}
                            />
                        </div>
                    </div>
                    <RectangleText
                        text={rectangeText}
                        className={styles.rectangeText}
                        ref={rectangleBox}
                    />
                </div>

                <div
                    className={styles.aboutTextBlockContainer}
                    ref={aboutTextBlockRef}
                >
                    <TitleTextBlock
                        className={styles.aboutBlock}
                        data={aboutTitleTextBlock}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(AboutHero);
