import React, { memo } from "react";
import classnames from "classnames";
import { PortableText } from "@portabletext/react";

import styles from "./RichText.module.scss";

import Link from "../Link/Link";

const portableTextSerializer = {
    marks: {
        link: ({ value }) => {
            console.log({ value });
            return <Link link={value}></Link>;
        },
        small: ({ children }) => {
            return <small>{children}</small>;
        },
    },
};

function RichText({ className, content, contentSize }) {
    return (
        <div
            className={classnames(styles.RichText, className)}
            data-content-size={contentSize}
            data-component="RichText"
        >
            <PortableText value={content} components={portableTextSerializer} />
        </div>
    );
}

export default memo(RichText);
