import React, { memo } from "react";
import classnames from "classnames";

import styles from "./RectangleText.module.scss";
import Link from "../Link/Link";

import ExternalLink from "../../assets/svgs/external-link.svg";

const RectangleText = React.forwardRef(({ className, text, link }, ref) => {
    if (!text) return null;

    return (
        <div ref={ref} className={classnames(styles.RectangleText, className)}>
            {text}
            {link && (
                <>
                    <Link className={styles.link} linkOnly link={link} />
                    <p className={styles.linkText}>
                        <span>{link.label}</span>
                    </p>
                    <ExternalLink className={styles.icon} />
                </>
            )}
        </div>
    );
});

export default memo(RectangleText);
