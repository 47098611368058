import React, { memo } from "react";
import classnames from "classnames";

import styles from "./Cube.module.scss";

const Cube = React.forwardRef(
    ({ frontContent, backContent, inverted, className }, ref) => {
        return (
            <div
                className={classnames(className, styles.cube, {
                    [styles.inverted]: inverted,
                })}
                ref={ref}
            >
                <div className={styles.cube__inner}>
                    <div className={styles.cube__front}>
                        {frontContent && frontContent}
                    </div>
                    <div className={styles.cube__back}>
                        {backContent && backContent}
                    </div>
                    <div className={styles.cube__left}></div>
                    <div className={styles.cube__right}></div>
                </div>
            </div>
        );
    }
);

export default memo(Cube);
