import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./BursariesAbout.module.scss";

import Cube from "../Cube/Cube";
import RichText from "../RichText/RichText";
import SanityImage from "../SanityImage/SanityImage";
import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function BursariesAbout({
    className,
    boxContent,
    equationImage,
    slidingScaleInfo,
}) {
    const cubeRef = useRef();
    const imageRef = useRef();
    const equationImageRef = useRef();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        gsap.killTweensOf([cubeRef.current, equationImageRef.current]);
        gsap.set([cubeRef.current, equationImageRef.current], {
            clearProps: "all",
        });

        gsap.to(cubeRef.current, {
            "--box-offset": isMobile ? "2.5rem" : "5rem",
            scrollTrigger: {
                trigger: cubeRef.current,
                start: "top bottom",
                end: "bottom top",
                scrub: 0.75,
            },
        });

        gsap.from(equationImageRef.current, {
            x: "-10rem",
            scrollTrigger: {
                trigger: equationImageRef.current,
                start: "top bottom",
                end: "50% 50%",
                scrub: 0.75,
            },
        });
    }, [isMobile]);

    if (!boxContent || !equationImage || !slidingScaleInfo) return null;

    return (
        <div className={classnames(styles.BursariesAbout, className)}>
            <div className={styles.inner}>
                <div className={styles.cubeContainer}>
                    <Cube
                        ref={cubeRef}
                        className={styles.cube}
                        frontContent={
                            <div className={styles.cubeFront}>
                                <RichText content={boxContent.content} />
                            </div>
                        }
                        backContent={<div className={styles.cubeBack} />}
                    />
                </div>
                <div className={styles.slidingScaleContainer}>
                    <div className={styles.slidingScaleContainer__header}>
                        <h2 className={styles.slidingScaleContainer__heading}>
                            {slidingScaleInfo.title}
                        </h2>
                    </div>
                    <div className={styles.slidingScaleContainer__content}>
                        <div
                            className={
                                styles.slidingScaleContainer__colHeadings
                            }
                        >
                            <div
                                className={
                                    styles.slidingScaleContainer__colHeading
                                }
                            >
                                Category
                            </div>
                            <div
                                className={
                                    styles.slidingScaleContainer__colHeading
                                }
                            >
                                Amount
                            </div>
                        </div>
                        <div
                            className={
                                styles.slidingScaleContainer__contentValueRows
                            }
                        >
                            {slidingScaleInfo.values.map((value, i) => (
                                <div
                                    key={i}
                                    className={
                                        styles.slidingScaleContainer__contentValues
                                    }
                                >
                                    <div
                                        className={
                                            styles.slidingScaleContainer__col1
                                        }
                                    >
                                        {value.category}
                                    </div>
                                    <div
                                        className={
                                            styles.slidingScaleContainer__col2
                                        }
                                    >
                                        {value.amount}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles.equationImageContainer}>
                    <SanityImage
                        ref={imageRef}
                        image={equationImage}
                        className={styles.equationImage}
                        ref={equationImageRef}
                    />
                </div>
            </div>
        </div>
    );
}

export default memo(BursariesAbout);
