import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./Gallery.module.scss";

import BoxHeading from "../BoxHeading/BoxHeading";
import SanityImage from "../SanityImage/SanityImage";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function Gallery({ className, title = "Gallery", images }) {
    const imageRefs = useRef([]);
    const imageContainerRefs = useRef([]);
    const boxHeadingRef = useRef();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (
            !imageRefs.current.length ||
            !imageContainerRefs.current.length ||
            !boxHeadingRef.current
        ) {
            return;
        }

        gsap.killTweensOf([
            ...imageRefs.current,
            ...imageContainerRefs.current,
            boxHeadingRef.current,
        ]);

        const ease = "Power3.easeOut";
        window.scrollTo(0, 0);

        gsap.fromTo(
            boxHeadingRef.current,
            {
                autoAlpha: 0,
                y: "10rem",
            },
            {
                y: 0,
                autoAlpha: 1,
                ease,
                duration: 1.2,
            }
        );

        gsap.fromTo(
            imageContainerRefs.current,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
                ease,
                duration: 1.2,
                stagger: 0.2,
                delay: 0.6,
            }
        );

        imageRefs.current.forEach((el, i) => {
            const container = imageContainerRefs.current[i];

            gsap.fromTo(
                el,
                {
                    scaleY: 3,
                    y: "120%",
                },
                {
                    scaleY: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: container,
                        start: "top bottom",
                        end: `50% ${isMobile ? "50%" : "70%"}`,
                        scrub: 0.75,
                    },
                }
            );
        });
    }, [isMobile]);

    if (!images.length) return null;

    return (
        <div className={classnames(styles.Gallery, className)}>
            <div ref={boxHeadingRef} className={styles.headingContainer}>
                <BoxHeading text={title} className={styles.heading} />
            </div>
            <div className={styles.images}>
                {images.map((item, i) => (
                    <div
                        key={i}
                        ref={(ref) => (imageContainerRefs.current[i] = ref)}
                        className={classnames(
                            styles.imageItem,
                            {
                                [styles[item.position]]: item.position,
                            },
                            {
                                [styles[
                                    item.image.asset.metadata.dimensions.width /
                                        item.image.asset.metadata.dimensions
                                            .height >
                                    1
                                        ? "is-landscape"
                                        : "is-portrait"
                                ]]: item.image.asset.metadata.dimensions.width,
                            }
                        )}
                    >
                        <SanityImage
                            className={styles.image}
                            image={item.image}
                            ref={(ref) => (imageRefs.current[i] = ref)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default memo(Gallery);
