import React, {
    memo,
    useEffect,
    useLayoutEffect,
    useState,
    Fragment,
    useRef,
} from "react";
import classnames from "classnames";
import Lottie from "lottie-react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./ScheduleSection.module.scss";

import BoxHeading from "../BoxHeading/BoxHeading";
import SanityImage from "../SanityImage/SanityImage";

import CrossLottie from "../../assets/lottie/x.json";

import Highlight from "../Highlight/Highlight";

// TODO Remove
import SpeakerTestImage from "../../assets/images/speaker.webp";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

const ALL_TYPE = {
    value: "all",
    label: "All",
};

const sortFunction = (a, b) =>
    new Date(a.session_date) - new Date(b.session_date);

function ScheduleSection({
    speakersData,
    scheduleData,
    title,
    images,
    className,
    sessionCodes,
}) {
    const [currentScheduleData, setCurrentScheduleData] = useState([]);
    const [activeScheduleDay, setActiveScheduleDay] = useState(null);

    let filteredType = {};
    sessionCodes.flat().forEach((obj) => {
        if (!obj.ticketTypes?.length) return;
        obj.ticketTypes.forEach((type) => {
            if (!filteredType[type.ticketCode]) {
                filteredType[type.ticketCode] = {
                    value: type.ticketCode,
                    label: type.title,
                };
            }
        });
    });

    const types = useRef([ALL_TYPE, ...Object.values(filteredType)]);
    const [type, setType] = useState(types.current[0]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [typeHovered, setTypeHovered] = useState(null);
    const [sessionIndexOpen, setSessionIndexOpen] = useState(-1);
    const { isMobile } = useBreakpoint();
    const firedIntroAnimation = useRef(false);
    const dataKeyLength = Object.keys(currentScheduleData).length;

    // Refs
    const boxHeadingContainerRef = useRef();
    const imageRefs = useRef([]);
    const imageComponentRefs = useRef([]);
    const scheduleNavRef = useRef();
    const scheduleContentRef = useRef();
    const containerRef = useRef();

    const passesBySessionCode = {};
    sessionCodes.forEach((obj) => {
        if (!obj.ticketTypes?.length || !obj.sessionCode) return;

        passesBySessionCode[obj.sessionCode] = [];
        obj.ticketTypes.forEach((type) => {
            passesBySessionCode[obj.sessionCode].push({
                value: type.ticketCode,
                label: type.title,
            });
        });
    });

    useEffect(() => {
        if (!speakersData.length || !scheduleData.length || dataKeyLength) {
            return;
        }

        function getDayName(dateString, locale = "en-US") {
            const split = dateString.split("-");
            const newDate = `${split[1]}-${split[2]}-${split[0]}`;

            // needs weird regex here
            // because safari is lame as heck
            // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
            const date = new Date(newDate.replace(/-/g, "/"));

            return {
                long: date.toLocaleDateString(locale, { weekday: "long" }),
                short: date.toLocaleDateString(locale, { weekday: "short" }),
            };
        }

        const speakersByCodeHashmap = {};
        speakersData.forEach((speaker) => {
            speakersByCodeHashmap[speaker.code] = speaker;
        });

        const sortedByDate = scheduleData.sort(sortFunction);

        const formattedScheduleData = {};
        sortedByDate.forEach((session) => {
            if (!session.end_time || !session.start_time || !session.title) {
                return;
            }

            session.speakers.forEach((speakerCode, i) => {
                if (!speakersByCodeHashmap[speakerCode]) return;
                session.speakers[i] = speakersByCodeHashmap[speakerCode];
            });

            if (!formattedScheduleData[session.session_date]) {
                formattedScheduleData[session.session_date] = {};
                const dayName = getDayName(session.session_date).long;
                const shortName = getDayName(session.session_date).short;
                formattedScheduleData[session.session_date].dayName = dayName;
                formattedScheduleData[session.session_date].dayNameShort =
                    shortName;
                formattedScheduleData[session.session_date].sessions = [];
            }

            let formattedStartAndEnd = "";
            if (session.start_time && session.end_time) {
                const startSplit = session.start_time.split(":");
                const endSplit = session.end_time.split(":");

                const startHour =
                    parseInt(startSplit[0]) > 12
                        ? parseInt(startSplit[0]) - 12
                        : parseInt(startSplit[0]);
                const endHour =
                    parseInt(endSplit[0]) > 12
                        ? parseInt(endSplit[0]) - 12
                        : parseInt(endSplit[0]);

                formattedStartAndEnd = `${startHour}:${startSplit[1]}-${endHour}:${endSplit[1]}`;
            }

            session.formatted_start_and_end = formattedStartAndEnd;

            if (passesBySessionCode[session.code]) {
                session.passesRequired = passesBySessionCode[session.code];
            }

            formattedScheduleData[session.session_date].sessions.push(session);
        });

        setCurrentScheduleData(formattedScheduleData);
    }, [speakersData, scheduleData, currentScheduleData]);

    const getIsClickableSession = (session) => {
        return session.about || session.speakers[0];
    };

    const handleOpenSession = (i, session) => {
        if (getIsClickableSession(session)) {
            setSessionIndexOpen(sessionIndexOpen === i ? -1 : i);
        }
    };

    useEffect(() => {
        if (!currentScheduleData || !activeScheduleDay) return;
        if (firedIntroAnimation.current) return;

        firedIntroAnimation.current = true;

        if (!boxHeadingContainerRef.current) return;
        const duration = 1.2;
        const ease = "Power3.easeOut";

        gsap.fromTo(
            [
                boxHeadingContainerRef.current,
                scheduleNavRef.current,
                scheduleContentRef.current,
            ],
            {
                y: "10rem",
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration,
                ease,
            }
        );

        gsap.fromTo(
            imageRefs.current,
            {
                y: "10rem",
                autoAlpha: 0,
            },
            {
                y: 0,
                autoAlpha: 1,
                duration,
                ease,
                stagger: 0.2,
                delay: 0.2,
            }
        );

        gsap.to(imageComponentRefs.current[1], {
            y: "-10vh",
            scrollTrigger: {
                trigger: containerRef.current,
                start: "top top",
                end: "bottom top",
                scrub: 0.75,
            },
        });
    }, [currentScheduleData, activeScheduleDay]);

    useEffect(() => {
        if (!dataKeyLength) return;
        const firstKey = Object.keys(currentScheduleData)[0];
        setActiveScheduleDay(firstKey);
    }, [dataKeyLength]);

    if (
        !speakersData?.length ||
        !scheduleData?.length ||
        !title ||
        !Object.keys(images).length
    ) {
        return null;
    }

    console.log({ currentScheduleData });

    return (
        <div
            ref={containerRef}
            className={classnames(styles.ScheduleSection, className, {
                [styles.formattingContent]: !activeScheduleDay,
            })}
            style={{
                "--schedule-columns": dataKeyLength + 1,
                "--button-width-mobile": `${100 / dataKeyLength}%`,
            }}
        >
            <div className={styles.inner}>
                <div
                    ref={boxHeadingContainerRef}
                    className={styles.boxHeadingContainer}
                >
                    <BoxHeading
                        text={title}
                        theme="black"
                        className={styles.boxHeading}
                    />
                </div>
                {activeScheduleDay && (
                    <div className={styles.scheduleContainer}>
                        <div className={styles.imagesContainer}>
                            <div
                                ref={(ref) => {
                                    imageRefs.current[0] = ref;
                                }}
                                className={styles.narrowImageContainer}
                            >
                                <SanityImage
                                    ref={(ref) =>
                                        (imageComponentRefs.current[0] = ref)
                                    }
                                    image={images.narrowImage}
                                    className={styles.narrowImage}
                                />
                            </div>
                            <div
                                ref={(ref) => {
                                    imageRefs.current[1] = ref;
                                }}
                                className={styles.wideImageContainer}
                            >
                                <SanityImage
                                    ref={(ref) =>
                                        (imageComponentRefs.current[1] = ref)
                                    }
                                    image={images.wideImage}
                                    className={styles.wideImage}
                                />
                            </div>
                        </div>
                        <div
                            ref={scheduleNavRef}
                            className={styles.scheduleNavigationContainer}
                        >
                            <div className={styles.scheduleTypeDropdown}>
                                <button
                                    className={
                                        styles.scheduleTypeDropdownButton
                                    }
                                    onClick={() => {
                                        setDropdownOpen((prev) => !prev);
                                    }}
                                >
                                    <span className={styles.scheduleTypeText}>
                                        {type.label}
                                    </span>
                                </button>
                                {dropdownOpen && (
                                    <div
                                        className={
                                            styles.scheduleTypeDropdownList
                                        }
                                    >
                                        {types.current.map((typeObj, i) => (
                                            <div
                                                className={classnames(
                                                    styles.scheduleTypeDropdownListItem,
                                                    {
                                                        [styles.disabled]:
                                                            type.value ===
                                                            typeObj.value,
                                                    }
                                                )}
                                                key={i}
                                                onMouseEnter={() => {
                                                    if (
                                                        type.value !==
                                                        typeObj.value
                                                    ) {
                                                        setTypeHovered(typeObj);
                                                    }
                                                }}
                                                onMouseLeave={() => {
                                                    setTypeHovered(null);
                                                }}
                                                onClick={() => {
                                                    setType(typeObj);
                                                    setDropdownOpen(false);
                                                }}
                                            >
                                                <div
                                                    className={
                                                        styles.scheduleTypeDropdownListItemLottieContainer
                                                    }
                                                >
                                                    {typeObj.value ===
                                                        typeHovered?.value && (
                                                        <Lottie
                                                            animationData={
                                                                CrossLottie
                                                            }
                                                            loop={false}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className={
                                                        styles.scheduleTypeDropdownListItemText
                                                    }
                                                >
                                                    {typeObj.label}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {Object.keys(currentScheduleData)
                                .sort((a, b) => new Date(a) - new Date(b))
                                .map((dayKey, i) => (
                                    <button
                                        key={i}
                                        className={classnames(
                                            styles.daySelector,
                                            {
                                                [styles.isActive]:
                                                    dayKey ===
                                                    activeScheduleDay,
                                            }
                                        )}
                                        onClick={() => {
                                            setActiveScheduleDay(dayKey);
                                            setSessionIndexOpen(-1);
                                            setType(ALL_TYPE);
                                        }}
                                    >
                                        {isMobile
                                            ? currentScheduleData[dayKey]
                                                  .dayNameShort
                                            : currentScheduleData[dayKey]
                                                  .dayName}
                                    </button>
                                ))}
                        </div>
                        <div
                            ref={scheduleContentRef}
                            className={styles.scheduleContentContainer}
                        >
                            <div className={styles.scheduleContentColHeadings}>
                                <div
                                    className={styles.scheduleContentColHeading}
                                >
                                    Location
                                </div>
                                <div
                                    className={styles.scheduleContentColHeading}
                                >
                                    Time
                                </div>
                                <div
                                    className={styles.scheduleContentColHeading}
                                >
                                    Workshop
                                </div>
                                <div
                                    className={styles.scheduleContentColHeading}
                                >
                                    Speaker
                                </div>
                                <div
                                    className={styles.scheduleContentColHeading}
                                >
                                    Ticket Access
                                </div>
                            </div>
                            <div className={styles.scheduleContent}>
                                {currentScheduleData[activeScheduleDay].sessions
                                    .sort((a, b) => {
                                        const aStartSplit =
                                            a?.start_time?.split(":");
                                        const aStart = parseInt(
                                            `${aStartSplit[0]}.${aStartSplit[1]}`
                                        );
                                        const bStartSplit =
                                            b?.start_time?.split(":");
                                        const bStart = parseInt(
                                            `${bStartSplit[0]}.${bStartSplit[1]}`
                                        );
                                        return aStart - bStart;
                                    })
                                    .map((session, i) => {
                                        if (
                                            !session?.passesRequired
                                                ?.map((type) => type?.value)
                                                ?.includes(type.value) &&
                                            type.value !== ALL_TYPE.value
                                        ) {
                                            return (
                                                <Fragment
                                                    key={`${session.event}_${i}`}
                                                ></Fragment>
                                            );
                                        }

                                        return (
                                            <div
                                                className={classnames(
                                                    styles.scheduleItem,
                                                    {
                                                        [styles.isOpen]:
                                                            sessionIndexOpen ===
                                                            i,
                                                    },
                                                    {
                                                        [styles.clickable]:
                                                            getIsClickableSession(
                                                                session
                                                            ),
                                                    }
                                                )}
                                                key={`${session.event}_${i}`}
                                            >
                                                <div
                                                    className={
                                                        styles.scheduleItemColumns
                                                    }
                                                    data-highlight-container
                                                    onClick={() => {
                                                        if (isMobile) {
                                                            handleOpenSession(
                                                                i,
                                                                session
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <Highlight
                                                        className={
                                                            styles.highlight
                                                        }
                                                        isLong={true}
                                                    />
                                                    <div
                                                        className={
                                                            styles.scheduleItemLocation
                                                        }
                                                        onClick={() => {
                                                            handleOpenSession(
                                                                i,
                                                                session
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.scheduleItemColumnHeading
                                                            }
                                                        >
                                                            {session.location
                                                                ? session.location
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.scheduleItemTime
                                                        }
                                                        onClick={() => {
                                                            handleOpenSession(
                                                                i,
                                                                session
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.scheduleItemColumnHeading
                                                            }
                                                        >
                                                            {session?.formatted_start_and_end
                                                                ? session?.formatted_start_and_end
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.scheduleItemWorkshop
                                                        }
                                                        onClick={() => {
                                                            handleOpenSession(
                                                                i,
                                                                session
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.scheduleItemColumnHeading
                                                            }
                                                        >
                                                            {session.title
                                                                ? session.title
                                                                : ""}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.scheduleItemSpeakers
                                                        }
                                                        onClick={() => {
                                                            handleOpenSession(
                                                                i,
                                                                session
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.scheduleItemColumnHeading
                                                            }
                                                        >
                                                            {session.speakers
                                                                ?.length >
                                                                0 && (
                                                                <>
                                                                    {session.speakers
                                                                        .map(
                                                                            (
                                                                                speaker
                                                                            ) =>
                                                                                `${
                                                                                    speaker.first_name
                                                                                }${
                                                                                    speaker.last_name
                                                                                        ? ` ${speaker.last_name}`
                                                                                        : ""
                                                                                }`
                                                                        )
                                                                        .join(
                                                                            ", "
                                                                        )}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.ticketAccess
                                                        }
                                                        onClick={() => {
                                                            handleOpenSession(
                                                                i,
                                                                session
                                                            );
                                                        }}
                                                    >
                                                        <div
                                                            className={
                                                                styles.scheduleItemColumnHeading
                                                            }
                                                        >
                                                            {session
                                                                ?.passesRequired
                                                                ?.length >
                                                                0 && (
                                                                <span>
                                                                    {session.passesRequired
                                                                        .map(
                                                                            (
                                                                                obj
                                                                            ) =>
                                                                                obj.label
                                                                        )
                                                                        .join(
                                                                            ", "
                                                                        )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className={
                                                        styles.scheduleItemContent
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.scheduleItemContent__imageContainer
                                                        }
                                                    >
                                                        {session.speakers[0] &&
                                                            (session.speakers[0]
                                                                ?.logo_company ||
                                                                session
                                                                    .speakers[0]
                                                                    ?.picture) && (
                                                                <img
                                                                    className={
                                                                        styles.scheduleItemContent__image
                                                                    }
                                                                    src={
                                                                        session
                                                                            .speakers[0]
                                                                            ?.picture ||
                                                                        session
                                                                            .speakers[0]
                                                                            ?.logo_company
                                                                    }
                                                                />
                                                            )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.scheduleItemContent__descriptionContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.scheduleItemContent__description
                                                            }
                                                        >
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: session.about,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default memo(ScheduleSection);
