import React, { memo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import checkProps from "@jam3/react-check-extra-props";
import gsap from "gsap";

import styles from "./RichTextSection.module.scss";

import BoxHeading from "../BoxHeading/BoxHeading";
import RichText from "../RichText/RichText";

function RichTextSection({ className, title, richText }) {
    const container = useRef();

    useEffect(() => {
        if (!container.current) return;

        gsap.fromTo(
            container.current,
            {
                autoAlpha: 0,
                y: "10rem",
            },
            {
                autoAlpha: 1,
                y: 0,
                duration: 1.3,
                ease: "Power3.easeOut",
            }
        );
    }, []);

    return (
        <div
            ref={container}
            className={classnames(styles.RichTextSection, className)}
        >
            <div className={styles.inner}>
                <BoxHeading className={styles.heading} text={title} />
                <RichText className={styles.rta} content={richText.content} />
            </div>
        </div>
    );
}

RichTextSection.propTypes = checkProps({
    className: PropTypes.string,
});

RichTextSection.defaultProps = {};

export default memo(RichTextSection);
