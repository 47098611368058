import React from "react";
import classnames from "classnames";

import CtaShape from "../../assets/svgs/cta-shape.svg";

import CtaSectionContent from "./CtaSectionContent";

import styles from "./CtaSection.module.scss";

const CtaSectionWeirdShape = React.forwardRef(
    ({ onMouseEnter, onMouseLeave, className, cta }, ref) => {
        return (
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={classnames(className, styles.WeirdShape)}
                ref={ref}
            >
                <div
                    className={classnames(
                        styles.weirdShape__front,
                        styles.weirdShape__side
                    )}
                >
                    <CtaShape />
                </div>
                <div
                    className={classnames(
                        styles.weirdShape__middle,
                        styles.weirdShape__side
                    )}
                >
                    <CtaSectionContent
                        className={styles.weirdShape__content}
                        cta={cta}
                    />
                    <CtaShape />
                </div>
                <div
                    className={classnames(
                        styles.weirdShape__back,
                        styles.weirdShape__side
                    )}
                >
                    <CtaShape />
                </div>
                <div
                    className={classnames(
                        styles.weirdShape__left,
                        styles.weirdShape__side
                    )}
                />
                <div
                    className={classnames(
                        styles.weirdShape__right,
                        styles.weirdShape__side
                    )}
                />
            </div>
        );
    }
);

export default CtaSectionWeirdShape;
