import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import gsap from "gsap";

import styles from "./FixedNavigationSection.module.scss";

import Link from "../Link/Link";
import Highlight from "../Highlight/Highlight";
import CtaArrow from "../../assets/svgs/cta-arrow.svg";

import useStore from "../../store";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

function FixedNavigationSection({
    className,
    desktopCtaText,
    mobileCtaText,
    desktopFixedNavigationText,
    cta,
    theme,
}) {
    const positionContainer = useRef();
    const observer = useRef();
    const windowWidth = useStore((state) => state.windowWidth);
    const thumbContainerRef = useRef();
    const textContainer = useRef();
    const bg = useRef();
    const title = useRef();
    const ctaContainerRef = useRef();
    const [rootBottomMargin, setRootBottomMargin] = useState(null);
    const [inView, setInView] = useState(false);
    const { isMobile } = useBreakpoint();
    const contentHeight = useStore((state) => state.contentHeight);

    useEffect(() => {
        if (!thumbContainerRef.current) return;

        const height = thumbContainerRef.current.offsetHeight;
        const distanceFromBottom =
            window.innerHeight -
            (thumbContainerRef.current.offsetTop +
                thumbContainerRef.current.offsetHeight);

        setRootBottomMargin(height + distanceFromBottom);

        positionContainer.current.style.setProperty("--height", `${height}px`);
    }, [windowWidth, contentHeight]);

    useEffect(() => {
        if (!positionContainer.current || !rootBottomMargin) {
            return;
        }

        if (observer.current) {
            observer.current.disconnect();
        }

        observer.current = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const isAfter =
                        entry.boundingClientRect.y < 0 && !entry.isIntersecting;

                    if (isAfter || entry.isIntersecting) {
                        setInView(true);
                    } else {
                        setInView(false);
                    }
                });
            },
            { threshold: 0, rootMargin: `0px 0px ${-rootBottomMargin}px 0px` }
        );

        observer.current.observe(positionContainer.current);

        return () => {
            observer.current?.disconnect();
        };
    }, [rootBottomMargin, windowWidth, contentHeight]);

    const introAnimation = () => {
        if (!ctaContainerRef.current) return;

        gsap.fromTo(
            ctaContainerRef.current,
            {
                autoAlpha: 0,
            },
            {
                autoAlpha: 1,
                delay: 1,
            }
        );
    };

    const animateIn = () => {
        if (!bg.current || !title.current) return;

        const ease = "Power4.easeOut";
        const duration = isMobile ? 0.6 : 1.2;

        gsap.killTweensOf([bg.current, title.current]);

        gsap.to(title.current, {
            autoAlpha: 1,
            ease,
            duration,
        });

        const height = textContainer.current.clientHeight;

        gsap.to(bg.current, {
            width: "calc(100% - var(--col-size))",
            height,
            ease,
            duration,
        });
    };

    const animateOut = () => {
        if (!bg.current || !title.current) return;

        const ease = "Power3.easeOut";
        const duration = 0.6;

        gsap.killTweensOf([bg.current, title.current]);

        gsap.to(title.current, {
            autoAlpha: 0,
            ease,
            duration,
        });

        const width = getComputedStyle(
            ctaContainerRef.current
        ).getPropertyValue("--desktop-thumb-width");
        const height = getComputedStyle(
            ctaContainerRef.current
        ).getPropertyValue("--desktop-thumb-height");

        gsap.to(bg.current, {
            width,
            height,
            ease,
            duration,
        });
    };

    useEffect(() => {
        inView ? animateIn() : animateOut();
    }, [inView]);

    useEffect(() => {
        introAnimation();
    }, []);

    if (
        !desktopCtaText ||
        !mobileCtaText ||
        !desktopFixedNavigationText ||
        !cta
    ) {
        return null;
    }

    return (
        <>
            <div
                className={classnames(styles.FixedNavigationSection, className)}
            >
                <div
                    ref={positionContainer}
                    className={styles.positionContainer}
                >
                    <div
                        className={classnames(
                            styles.ctaContainer,
                            {
                                [styles[theme]]: theme,
                            },
                            {
                                [styles.static]: inView,
                            }
                        )}
                        ref={ctaContainerRef}
                    >
                        <div
                            className={styles.ctaContainer__textContainer}
                            ref={textContainer}
                        >
                            <h1
                                ref={title}
                                className={styles.ctaContainer__text}
                            >
                                {desktopCtaText}
                            </h1>
                        </div>
                        <div className={styles.ctaContainer__bg} ref={bg} />
                        <div
                            className={styles.ctaContainerThumbContainer}
                            ref={thumbContainerRef}
                        >
                            <div
                                className={
                                    styles.ctaContainerThumbContainer__top
                                }
                            >
                                {isMobile
                                    ? mobileCtaText
                                    : desktopFixedNavigationText}
                            </div>
                            <div
                                className={
                                    styles.ctaContainerThumbContainer__ctaContainer
                                }
                                data-highlight-container
                            >
                                <Link
                                    link={cta}
                                    className={
                                        styles.ctaContainerThumbContainer__cta
                                    }
                                />
                                <Highlight
                                    className={
                                        styles.ctaContainerThumbContainer__highlight
                                    }
                                />
                                <CtaArrow
                                    className={
                                        styles.ctaContainerThumbContainer__ctaTriangle
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(FixedNavigationSection);
