import React, { memo, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { useMove } from "@use-gesture/react";
import { useInView } from "react-intersection-observer";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./CtaSection.module.scss";

import WeirdShape from "./CtaSectionWeirdShape";
import RectShape from "./CtaSectionRectShape";

import { lerp } from "../../utils";

import useStore from "../../store";

const LERP = 0.08;
const MULTIPLIER_BASE = 0;

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function CtaSection({ ctas, className }) {
    const { ref: inViewRef, inView } = useInView();
    const tl = useRef(null);
    const weirdShapeRef = useRef();
    const rectShapeRef = useRef();
    const scrollTrigger = useRef();
    const containerRef = useRef();
    const targetRef = useRef({ x: 0, y: 0, xNorm: 0 });
    const posRef = useRef({ x: 0, y: 0, xNorm: 0 });
    const windowWidth = useStore((state) => state.windowWidth);
    const windowHeight = useStore((state) => state.windowHeight);
    const requestRef = useRef(null);
    const { isMobile } = useBreakpoint();
    const homeIsFinishedAnimating = useStore(
        (state) => state.homeIsFinishedAnimating
    );

    const hoverMultipliers = useRef({
        left: MULTIPLIER_BASE,
        right: MULTIPLIER_BASE,
    });
    const [sideHovering, setSideHovering] = useState(null);

    const handleMove = (e) => {
        const [x, y] = e.xy;

        const percentX = x / windowWidth;
        const percentY = y / windowHeight;

        targetRef.current.x = percentX;
        targetRef.current.y = percentY;
        targetRef.current.xNorm =
            percentX > 0.5 ? percentX * 2 - 1 : -1 * (1 - percentX * 2);
    };

    useEffect(() => {
        if (!homeIsFinishedAnimating) return;
        if (scrollTrigger?.current) {
            scrollTrigger.current.kill();
        }

        tl.current = gsap.timeline();

        const duration = 1;

        tl.current.from(weirdShapeRef.current, {
            y: "25vh",
            duration,
        });

        tl.current.from(
            rectShapeRef.current,
            {
                y: "55vh",
                duration,
            },
            0
        );

        scrollTrigger.current = new ScrollTrigger({
            trigger: containerRef.current,
            start: `-=${window.innerHeight * 0.75}px`,
            end: "bottom bottom",
            scrub: 0.75,
            animation: tl.current,
        });
    }, [homeIsFinishedAnimating]);

    const animateHover = () => {
        requestRef.current = requestAnimationFrame(animateHover);

        posRef.current = {
            x: lerp(posRef.current.x, targetRef.current.x, LERP),
            y: lerp(posRef.current.y, targetRef.current.y, LERP),
            xNorm: lerp(posRef.current.xNorm, targetRef.current.xNorm, LERP),
        };

        const normalizedX = (posRef.current.xNorm - 0.5) * -1;

        const leftX =
            Math.abs(posRef.current.x) * hoverMultipliers.current.left;
        const rightX = posRef.current.x * hoverMultipliers.current.right;

        const leftMultiplier = hoverMultipliers.current.left * posRef.current.y;
        const rightMultiplier =
            hoverMultipliers.current.right * posRef.current.y;

        gsap.set(weirdShapeRef.current, {
            "--offset": `${Math.abs(leftMultiplier * 3 + 1 + leftX)}rem`,
            rotate: leftX * 3 - posRef.current.y * 1.5,
            skewY: leftX > 0 ? leftX : 0,
            skewX: leftX > 0 ? 0 : -leftX * 0.6,
        });

        gsap.set(rectShapeRef.current, {
            "--offset": `${Math.abs(rightMultiplier * 2 + 1 + rightX)}rem`,
            rotate: rightX * 2 - posRef.current.y * -1.5,
            skewY: rightX > 0 ? rightX : 0,
            skewX: rightX > 0 ? 0 : -rightX * 0.5,
        });
    };

    useEffect(() => {
        if (isMobile) return;

        if (inView) {
            animateHover();
        } else {
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
                requestRef.current = null;
            }
        }

        return () => {
            if (requestRef.current) cancelAnimationFrame(requestRef.current);
        };
    }, [inView, isMobile]);

    useEffect(() => {
        if (isMobile) return;

        gsap.killTweensOf(hoverMultipliers.current, "left,right");

        gsap.to(hoverMultipliers.current, {
            left: sideHovering === "LEFT" ? 1 : 0.1,
            right: sideHovering === "RIGHT" ? 1 : 0.1,
            duration: 0.8,
            ease: "Power3.easeOut",
        });
    }, [sideHovering, isMobile]);

    const bind = useMove((state) => handleMove(state));

    if (ctas.length !== 2) return null;

    return (
        <div
            {...bind()}
            ref={containerRef}
            className={classnames(styles.CtaSection, className)}
        >
            <div ref={inViewRef} />
            <WeirdShape
                onMouseEnter={() => {
                    setSideHovering("LEFT");
                }}
                onMouseLeave={() => {
                    setSideHovering(null);
                }}
                ref={weirdShapeRef}
                cta={ctas[0]}
            />
            <RectShape
                onMouseEnter={() => {
                    setSideHovering("RIGHT");
                }}
                onMouseLeave={() => {
                    setSideHovering(null);
                }}
                ref={rectShapeRef}
                cta={ctas[1]}
            />
            <svg
                className={styles.circle}
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="49"
                    cy="49"
                    r="49"
                    fill="transparent"
                    stroke="currentColor"
                    strokeWidth="1"
                    vectorEffect="non-scaling-stroke"
                />
            </svg>
        </div>
    );
}

export default memo(CtaSection);
