import React, { memo } from "react";
import classnames from "classnames";

import SanityImage from "../SanityImage/SanityImage";

import styles from "./LogosSection.module.scss";

function LogosSection({ className, title, logos }) {
    if (!title || !logos.length) return;

    return (
        <div className={classnames(styles.LogosSection, className)}>
            <div className={styles.inner}>
                <h1 className={styles.title}>{title}</h1>
                <ul className={styles.logoList}>
                    {logos.map((logo, i) => (
                        <li
                            className={classnames(styles.logoContainer, {
                                [styles[
                                    logo.asset.metadata.dimensions.width /
                                        logo.asset.metadata.dimensions.height >
                                    2.5
                                        ? "is-long"
                                        : ""
                                ]]: logo.asset.metadata.dimensions.width,
                            })}
                            key={i}
                        >
                            <SanityImage className={styles.logo} image={logo} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default memo(LogosSection);
