import React, { memo, useEffect, useRef, useState } from "react";
import { PortableText } from "@portabletext/react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./HomeHero.module.scss";

import useBreakpoint from "../../utils/hooks/use-breakpoint";
import useStore from "../../store";

import SanityImage from "../SanityImage/SanityImage";

import HomeHeroCircle from "../../assets/images/home-hero-circle.webp";
import HomeHeroCircleYellow from "../../assets/images/home-hero-circle-yellow.webp";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

const BubbleText = React.forwardRef(({ className, text, isYellow }, ref) => {
    return (
        <div ref={ref} className={classnames(className, styles.bubbleText)}>
            <img
                className={styles.bubbleTextCircle}
                src={isYellow ? HomeHeroCircleYellow.src : HomeHeroCircle.src}
                alt=""
            />
            {text}
        </div>
    );
});

function HomeHero({
    className,
    title,
    month,
    dates,
    year,
    imageDesktop,
    imageMobile,
    bubbleText,
    richText,
}) {
    const { isMobile } = useBreakpoint();
    const scrollTrigger = useRef();
    const [introAnimationComplete, setIntroAnimationComplete] = useState(false);
    const setHomeIsFinishedAnimating = useStore(
        (state) => state.setHomeIsFinishedAnimating
    );

    // Refs
    const titleRef = useRef();
    const titleUnderRef = useRef();
    const heroImageTitleContainerRef = useRef();
    const heroImageTitleContainerOverRef = useRef();
    const containerRef = useRef();
    const imageRef = useRef();
    const titleSmallTextRefs = useRef([]);
    const titleSmallTextBehindRefs = useRef([]);
    const bubbleRefs = useRef([]);
    const textContent = useRef();

    const onAnimationComplete = () => {
        setHomeIsFinishedAnimating(true);
        setIntroAnimationComplete(true);
        enableScrollTrigger();
    };

    const enableScrollTrigger = () => {
        if (scrollTrigger?.current) {
            scrollTrigger.current.kill();
        }

        const isTouch = "ontouchstart" in document.documentElement;

        const tl = gsap.timeline();

        const duration = 1.4;
        const ease = "Power4.easeInOut";
        const titleOffsetDistanceRems = -10;

        tl.to(
            titleUnderRef.current,
            {
                y: `${titleOffsetDistanceRems}rem`,
                duration,
            },
            0
        );

        tl.to(
            titleRef.current,
            {
                y: `${titleOffsetDistanceRems}rem`,
                duration,
            },
            0
        );

        tl.to(
            heroImageTitleContainerOverRef.current,
            {
                "--scroll-animation-top-x": "100%",
                duration,
                ease,
            },
            0
        );

        tl.to(
            heroImageTitleContainerOverRef.current,
            {
                "--scroll-animation-bottom-x": "100%",
                duration,
                ease,
                delay: 0.1,
            },
            0
        );

        const smallTextOffset = titleOffsetDistanceRems * 0.4;

        tl.to(
            titleSmallTextRefs.current,
            {
                y: `${smallTextOffset}rem`,
                duration,
                ease,
                stagger: 0.03,
            },
            0
        );

        tl.to(
            titleSmallTextBehindRefs.current,
            {
                y: `${smallTextOffset}rem`,
                duration,
                ease,
                stagger: 0.05,
            },
            0
        );

        tl.from(
            textContent.current,
            {
                duration,
                ease,
                y: "50vh",
            },
            0
        );

        gsap.to(bubbleRefs.current, {
            scrollTrigger: {
                trigger: containerRef.current,
                end: "900%",
                start: 0,
                scrub: 1.6,
            },
            y: "-150vh",
        });

        scrollTrigger.current = new ScrollTrigger({
            trigger: containerRef.current,
            start: 0,
            end: "150%",

            pin: true,
            scrub: 1, // THIS IS REQUIRED
            animation: tl,
        });
    };

    const animateIn = () => {
        const tl = gsap.timeline({
            onComplete: () => {
                onAnimationComplete();
            },
        });
        const duration = 1.4;
        const ease = "Power4.easeInOut";

        tl.to(
            heroImageTitleContainerOverRef.current,
            {
                "--intro-animation-top-x": "100%",
                duration,
                ease,
            },
            0
        );

        tl.to(
            heroImageTitleContainerOverRef.current,
            {
                "--intro-animation-bottom-x": "100%",
                duration,
                ease,
                delay: 0.1,
            },
            0
        );

        tl.fromTo(
            titleRef.current,
            {
                y: "40%",
            },
            {
                duration,
                ease,
                y: 0,
            },
            0
        );

        tl.fromTo(
            bubbleRefs.current,
            {
                autoAlpha: 0,
                rotate: "10deg",
                y: 40,
            },
            {
                duration,
                ease,
                rotate: 0,
                autoAlpha: 1,
                y: 0,
            },
            0.2
        );
    };

    useEffect(() => {
        animateIn();

        return () => {
            scrollTrigger.current?.kill();
        };
    }, []);

    return (
        <div
            className={classnames(styles.HomeHero, className, {
                [styles.introAnimationComplete]: introAnimationComplete,
            })}
            ref={containerRef}
        >
            <div className={styles.heroInner}>
                <div
                    className={styles.heroTextImageContainer}
                    ref={heroImageTitleContainerRef}
                >
                    <div className={styles.heroTextImageContainerUnder}>
                        <div
                            className={classnames(
                                styles.monthText,
                                styles.top,
                                styles.isBehind
                            )}
                            ref={(ref) =>
                                (titleSmallTextBehindRefs.current[0] = ref)
                            }
                        >
                            {month}
                        </div>
                        <div
                            className={classnames(
                                styles.dateText,
                                styles.top,
                                styles.isBehind
                            )}
                            ref={(ref) =>
                                (titleSmallTextBehindRefs.current[1] = ref)
                            }
                        >
                            {dates}
                        </div>
                        <div
                            className={classnames(
                                styles.yearText,
                                styles.top,
                                styles.isBehind
                            )}
                            ref={(ref) =>
                                (titleSmallTextBehindRefs.current[2] = ref)
                            }
                        >
                            {year}
                        </div>
                        <div className={styles.heroHeadingBehind}>
                            <div
                                ref={titleUnderRef}
                                className={classnames(
                                    styles.title,
                                    styles.titleBehind
                                )}
                            >
                                {title}
                            </div>
                        </div>
                        <div className={styles.bubbleTextUnderContainer}>
                            <div className={styles.bubbleTextUnder}>
                                <BubbleText
                                    ref={(ref) => (bubbleRefs.current[1] = ref)}
                                    text={bubbleText}
                                    isYellow
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.heroTextImageContainerOver}
                        ref={heroImageTitleContainerOverRef}
                    >
                        <div className={styles.bubbleTextOverContainer}>
                            <div className={styles.bubbleTextOver}>
                                <BubbleText
                                    ref={(ref) => (bubbleRefs.current[0] = ref)}
                                    text={bubbleText}
                                />
                            </div>
                        </div>
                        <div
                            className={classnames(styles.monthText, styles.top)}
                            ref={(ref) => (titleSmallTextRefs.current[0] = ref)}
                        >
                            {month}
                        </div>
                        <div
                            className={classnames(
                                styles.monthText,
                                styles.bottom
                            )}
                            ref={(ref) => (titleSmallTextRefs.current[3] = ref)}
                        >
                            {month}
                        </div>
                        <div
                            className={classnames(styles.dateText, styles.top)}
                            ref={(ref) => (titleSmallTextRefs.current[1] = ref)}
                        >
                            {dates}
                        </div>
                        <div
                            className={classnames(
                                styles.dateText,
                                styles.bottom
                            )}
                            ref={(ref) => (titleSmallTextRefs.current[4] = ref)}
                        >
                            {dates}
                        </div>
                        <div
                            className={classnames(styles.yearText, styles.top)}
                            ref={(ref) => (titleSmallTextRefs.current[2] = ref)}
                        >
                            {year}
                        </div>
                        <div
                            className={classnames(
                                styles.yearText,
                                styles.bottom
                            )}
                            ref={(ref) => (titleSmallTextRefs.current[5] = ref)}
                        >
                            {year}
                        </div>
                        <div className={styles.heroHeading}>
                            <h1 ref={titleRef} className={styles.title}>
                                {title}
                            </h1>
                        </div>
                        <SanityImage
                            className={styles.heroImage}
                            image={isMobile ? imageMobile : imageDesktop}
                            ref={imageRef}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.rtaGrid} ref={textContent}>
                <div className={styles.rta}>
                    <PortableText value={richText.content} />
                </div>
            </div>
        </div>
    );
}

export default memo(HomeHero);
