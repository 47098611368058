import classNames from "classnames";
import React from "react";

import Link from "../Link/Link";

import styles from "./CtaSection.module.scss";

import Highlight from "../Highlight/Highlight";

import CtaArrow from "../../assets/svgs/cta-arrow.svg";

const CtaSectionContent = React.forwardRef(({ className, cta }, ref) => {
    return (
        <div ref={ref} className={classNames(styles.sectionContent, className)}>
            <div className={styles.sectionContent__title}>{cta.title}</div>
            <div
                data-highlight-container
                className={styles.sectionContent__cta}
            >
                <Link className={styles.sectionContent__link} link={cta.cta}>
                    <span className={styles.sectionContent__linkText}>
                        {cta.cta.label}
                    </span>
                    <CtaArrow />
                    <Highlight />
                </Link>
            </div>
        </div>
    );
});

export default CtaSectionContent;
