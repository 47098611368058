import React, { memo, useEffect, useRef } from "react";
import classnames from "classnames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

import styles from "./GetInvolvedPartnerHostSection.module.scss";

import TitleTextBlock from "../TitleTextBlock/TitleTextBlock";
import SanityImage from "../SanityImage/SanityImage";
import Cube from "../Cube/Cube";
import RichText from "../RichText/RichText";

import useBreakpoint from "../../utils/hooks/use-breakpoint";

ScrollTrigger.config({
    ignoreMobileResize: true,
});

gsap.registerPlugin(ScrollTrigger);

function GetInvolvedPartnerHostSection({
    className,
    partnerTitleTextBlock,
    boxContent,
    images,
}) {
    const imageRefs = useRef([]);
    const boxRef = useRef();
    const titleTextBlockRef = useRef();
    const cubeSectionRef = useRef();
    const containerRef = useRef();
    const scrollTrigger = useRef();
    const scrollTriggerBox = useRef();
    const cubeContainerRef = useRef();

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        if (scrollTrigger?.current) {
            scrollTrigger.current.kill();
        }

        gsap.killTweensOf([imageRefs.current[2]]);

        const tl = gsap.timeline();

        tl.to(titleTextBlockRef.current, {
            y: "-50rem",
            ease: "linear",
        });

        tl.to(
            imageRefs.current[0],
            {
                x: "20rem",
                ease: "linear",
            },
            0
        );

        if (!isMobile) {
            tl.to(
                imageRefs.current[2],
                {
                    y: "45rem",
                    ease: "linear",
                },
                0
            );
        } else {
            gsap.to(imageRefs.current[2], {
                y: "30rem",
                scrollTrigger: {
                    start: "top bottom",
                    end: "bottom top",
                    trigger: imageRefs.current[2],
                    scrub: 0.75,
                },
            });
        }

        scrollTrigger.current = new ScrollTrigger({
            trigger: containerRef.current,
            start: `top bottom`,
            end: "bottom top",
            scrub: 0.75,
            animation: tl,
        });
    }, [isMobile]);

    useEffect(() => {
        const tl = gsap.timeline();

        if (scrollTriggerBox?.current) {
            scrollTriggerBox.current.kill();
        }

        tl.to(boxRef.current, {
            "--box-offset": isMobile ? "2rem" : "5rem",
        });

        scrollTriggerBox.current = new ScrollTrigger({
            trigger: cubeContainerRef.current,
            start: "top top",
            end: `bottom bottom-=${window.innerHeight * 2}`,
            pin: true,
            scrub: 0.7,
            animation: tl,
        });
    }, [isMobile]);

    useEffect(() => {
        return () => {
            scrollTrigger.current?.kill();
            scrollTriggerBox.current?.kill();
        };
    }, []);

    if (!partnerTitleTextBlock || !boxContent || !images) return null;

    return (
        <section
            className={classnames(
                styles.GetInvolvedPartnerHostSection,
                className
            )}
            ref={containerRef}
        >
            <div className={styles.inner}>
                <div className={styles.topSection}>
                    <div className={styles.leftImages}>
                        <SanityImage
                            image={images.equationImage}
                            className={styles.equationImage}
                            ref={(ref) => (imageRefs.current[0] = ref)}
                        />
                        <SanityImage
                            image={images.lineImage}
                            className={styles.lineImage}
                            ref={(ref) => (imageRefs.current[1] = ref)}
                        />
                    </div>
                    <TitleTextBlock
                        className={styles.titleTextBlock}
                        data={partnerTitleTextBlock}
                        containerRef={titleTextBlockRef}
                    />
                </div>
                <div className={styles.cubeSection} ref={cubeSectionRef}>
                    <SanityImage
                        image={images.geometryImage}
                        className={styles.geometryImage}
                        ref={(ref) => (imageRefs.current[2] = ref)}
                    />
                    <div
                        className={styles.cubeContainer}
                        ref={cubeContainerRef}
                        id="host-a-community-event"
                    >
                        <Cube
                            ref={boxRef}
                            inverted
                            className={styles.cube}
                            frontContent={
                                <div className={styles.cube__frontText}>
                                    <h1 className={styles.cube__frontHeading}>
                                        {boxContent.title}
                                    </h1>
                                    <RichText
                                        content={boxContent.richText.content}
                                    />
                                </div>
                            }
                            backContent={
                                <div className={styles.cube__backContent} />
                            }
                        />
                    </div>
                    <SanityImage
                        image={images.lowerEquationImage}
                        className={styles.lowerEquationImage}
                        ref={(ref) => (imageRefs.current[3] = ref)}
                    />
                </div>
            </div>
        </section>
    );
}

export default memo(GetInvolvedPartnerHostSection);
